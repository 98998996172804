import { useRequest } from "@/hooks/useRequest";
import { Category } from "@/shared/types/categories";
import { useQuery } from "@tanstack/react-query";

export const useFetchCategories = () => {
  const { sendRequest } = useRequest();

  const fetchCategories = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: "Categories",
      });
      const data: {
        data: {
          $values: Category[];
        };
      } = await res.data;
      const categories = data.data.$values;
      const filteredCategories = categories.filter(
        (category) =>
          category.name === "Audio" ||
          category.name === "Video" ||
          category.name === "Photo"
      );
      return filteredCategories.sort((a, b) => {
        const order = ["Photo", "Video", "Audio"];
        return order.indexOf(a?.name ?? "") - order.indexOf(b?.name ?? "");
      });
    } catch (error) {
      console.error(error);
    }
  };

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return { categories };
};
