import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchUserInfo } from "../api";
import {
  updateLocalPfp,
  userInfoSelector,
  userRolesSelector,
  userSelector,
} from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useRequest } from "@/hooks/useRequest";
import { generatePfpFileName } from "@/features/PfpUploader/utils/helpers";
import {
  ensureUrlPrefix,
  ensureUsernamePrefix,
  getBaseDomain,
} from "@/utils/helpers";
import { cn } from "@/utils/utils";
import ProgressBar from "@/layouts/CreatorDashboardLayout/components/ProgressBar";
import toast from "react-hot-toast";
import PfpUpload from "@/components/Header/UserDropdown/PfpUpload";
import DefaultAvatar from "@/components/Avatar/DefaultAvatar";
import ProgressModal from "./ProgressModal";

import { IoIosGlobe } from "react-icons/io";
import { FaInstagram, FaTiktok, FaDiscord } from "react-icons/fa";

const UserCard = () => {
  const dispatch = useAppDispatch();
  const { sendRequest, loading } = useRequest();
  const [expandedBio, setExpandedBio] = useState(false);
  const userId = useAppSelector(userSelector)?.id;
  const kycStatus = useAppSelector(userInfoSelector)?.kycStatus;
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const [progressModal, showProgressModal] = useState(false);

  const { data: userInfo } = useQuery({
    queryKey: ["userInfo", userId],
    queryFn: fetchUserInfo,
    gcTime: 1000 * 60 * 60,
    staleTime: 1000 * 60 * 60,
  });

  const handlePfpUpload = async (image: File) => {
    const formData = new FormData();
    formData.append("file", image, generatePfpFileName());

    try {
      await sendRequest({
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "Users",
        data: formData,
      });
      // Call UserInfo again to update Pfp
      fetchUserInfo();
      // Update PFP locally as well
      dispatch(updateLocalPfp(URL.createObjectURL(image)));
      toast.success("Your profile picture has been updated!");
    } catch (error) {
      toast.error("Sorry, your profile picture could not be updated.");
    }
  };

  useEffect(() => {
    const uploadsNr = userInfo?.contentCount || 0;
    if (
      (uploadsNr >= 100 && userInfo?.hasSeenUpload100Popup === false) ||
      (uploadsNr === 500 && userInfo?.hasSeenUpload500Popup === false)
    ) {
      showProgressModal(true);
    }
  }, [
    userInfo?.hasSeenUpload100Popup,
    userInfo?.hasSeenUpload500Popup,
    userInfo?.contentCount,
  ]);

  return (
    <div className="lg:bg-tertiary-50 lg:dark:bg-tertiary-dark-gray rounded-2xl text-center">
      <div
        className="w-full h-[106px] bg-cover max-lg:rounded-b-2xl rounded-t-2xl"
        style={{
          backgroundImage: "url('/images/profile/cover.jpg')",
          backgroundPositionY: "70%",
        }}
      ></div>
      <div className="relative -mt-[64px] mb-4 mx-auto size-[100px]">
        <DefaultAvatar
          src={userInfo?.profilePicture}
          alt={userInfo?.fullName}
          kycStatus={kycStatus ?? 0}
        />
        <PfpUpload
          loading={loading}
          onUpload={handlePfpUpload}
          containerClassName={cn("absolute", {
            "top-0 -end-[5px]": kycStatus,
            "bottom-0 end-0": !kycStatus,
          })}
          buttonClassName="!size-[32px]"
        />
      </div>
      <div className="px-6 pb-[24px]">
        <h3 className="max-lg:text-[24px] truncate">
          {userInfo?.fullName || ""}
        </h3>
        {userInfo?.portfolioLink && (
          <a
            className="text-tertiary flex items-center gap-1 lg:gap-2 unstyled-link justify-center text-sm font-medium max-lg:mt-2"
            href={ensureUrlPrefix(userInfo?.portfolioLink)}
            rel="noreferrer"
            target="_blank"
          >
            <IoIosGlobe size={22} className="shrink-0" />
            <span className="truncate">
              {getBaseDomain(userInfo?.portfolioLink)}
            </span>
          </a>
        )}
        {isCreator && <ProgressBar progress={userInfo?.contentCount || 0} />}
        {isCreator && (
          <ProgressModal
            progress={userInfo?.contentCount || 0}
            show={progressModal}
            onClose={() => showProgressModal(false)}
          />
        )}
        <p className="text-[#5B5858] dark:text-white text-xs mt-3 lg:mt-4 leading-[20px] break-words">
          {expandedBio ? userInfo?.about : userInfo?.about?.slice(0, 122)}{" "}
          {(userInfo?.about?.length || 0) > 122 && (
            <button
              className="font-semibold ms-1"
              onClick={() => setExpandedBio(!expandedBio)}
            >
              {expandedBio ? "Collapse" : "Read more..."}
            </button>
          )}
        </p>
        <div className="flex items-center justify-center gap-[20px] mt-[10px]">
          {userInfo?.instagram && (
            <a
              href={`https://www.instagram.com/${userInfo?.instagram}`}
              rel="noreferrer"
              target="_blank"
            >
              <FaInstagram size={22} />
            </a>
          )}
          {userInfo?.discord && (
            <a
              href={ensureUrlPrefix(userInfo?.discord)}
              rel="noreferrer"
              target="_blank"
            >
              <FaDiscord size={22} />
            </a>
          )}
          {userInfo?.tiktok && (
            <a
              href={`https://www.tiktok.com/${ensureUsernamePrefix(
                userInfo?.tiktok,
                "@"
              )}`}
              rel="noreferrer"
              target="_blank"
            >
              <FaTiktok size={22} />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserCard;
