import { useEffect, useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import Button, { ButtonVariants } from "@/components/Button";
import HeartOutline from "@/assets/icons/hearts/heart_outline.svg?react";
import HeartFilled from "@/assets/icons/hearts/heart_filled.svg?react";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import { cn } from "@/utils/utils";

interface FavButtonProps {
  className?: string;
  productId?: string;
  isFavorite?: boolean;
  muted?: boolean;
  iconClassName?: string;
  variant?: ButtonVariants;
}

const FavButton = ({
  className,
  productId,
  isFavorite,
  muted,
  iconClassName,
  variant = "default-dimmed",
}: FavButtonProps) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { sendRequest } = useRequest();
  const [isLiked, setIsLiked] = useState(false);
  const [scope, animate] = useAnimate();

  const handleFavoriteClick = async () => {
    if (!isLoggedIn) return;
    setIsLiked(!isLiked);
    // Call Favorite API
    if (productId) {
      try {
        await sendRequest({
          method: isLiked ? "DELETE" : "POST",
          url: `ProductFavorite/${productId}`,
        });
      } catch (error) {}
    }
  };

  useEffect(() => {
    const animateFav = async () => {
      await animate(
        scope.current,
        { scale: 0.5 },
        {
          duration: 0.5,
        }
      );
      await animate(
        scope.current,
        { scale: 1 },
        {
          duration: 0.5,
        }
      );
    };

    if (isLiked) {
      animateFav();
    }
  }, [animate, isFavorite, isLiked, scope]);

  // Detect if content already liked
  useEffect(() => {
    if (isFavorite) setIsLiked(true);
  }, [isFavorite]);

  return (
    <Button
      tiny
      variant={muted ? "transparent-inverted" : variant}
      className={cn("relative", className)}
      href={
        isLoggedIn
          ? undefined
          : `/sign-in${productId ? `?target=/product/${productId}` : ""}`
      }
      aria-label="Add to Favorites"
      onClick={() => handleFavoriteClick()}
      iconClassName="size-[16px]"
    >
      <div ref={scope}>
        <HeartOutline
          className={cn(
            "size-[20px]",
            {
              "text-error-default dark:text-error-dark-default": isLiked,
              "text-tertiary ": muted && !isLiked,
            },
            iconClassName
          )}
        />
      </div>

      <AnimatePresence>
        {isLiked && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.7, type: "spring" }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <HeartFilled
              className={cn(
                "fill-error-default dark:fill-error-dark-default size-[20px]",
                iconClassName
              )}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </Button>
  );
};

export default FavButton;
