import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/utils/utils";
import CircleLoader from "@/components/Loaders/CircleLoader";

import PlayIcon from "@/assets/icons/video/play.svg?react";
import PauseIcon from "@/assets/icons/video/pause.svg?react";
import ErrorIcon from "@/assets/icons/warnings/exclamation_circle.svg?react";

interface PlayButtonProps {
  isPlaying?: boolean;
  onClick?: () => void;
  atEnd?: boolean;
  isBuffering?: boolean;
  isVisible?: boolean;
  className?: string;
  alwaysVisible?: boolean;
  error?: boolean;
  isPointer?: boolean;
  small?: boolean;
  iconClassName?: string;
}

const PlayButton = ({
  isPlaying,
  onClick,
  atEnd,
  isBuffering,
  isVisible,
  className,
  alwaysVisible,
  error,
  isPointer,
  small,
  iconClassName,
}: PlayButtonProps) => {
  return (
    <button
      className={cn(
        "size-[50px] rounded-full bg-tertiary-925/45 backdrop-blur flex items-center justify-center transition-opacity duration-300",
        {
          "group-hover:opacity-100": !alwaysVisible && !isPointer,
          "opacity-0": (isPointer ? !isVisible : isPlaying) && !alwaysVisible,
          "has-tooltip": error,
        },
        className
      )}
      type="button"
      onClick={onClick}
      data-tooltip-text="Error"
      aria-label="Play/Pause"
    >
      {error && (
        <ErrorIcon className="text-error-default dark:text-error-dark-default" />
      )}
      {isBuffering && !error && (
        <CircleLoader progress={50} className="size-[24px]" />
      )}
      {!error && !isBuffering && (
        <AnimatePresence mode="wait">
          {isPlaying ? (
            <motion.div
              key="pause"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.1 }}
            >
              <PauseIcon
                className={cn(
                  "text-white",
                  {
                    "size-[18px]": !small,
                    "size-[14px]": small,
                  },
                  iconClassName
                )}
              />
            </motion.div>
          ) : (
            <motion.div
              key="play"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{ duration: 0.1 }}
            >
              <PlayIcon
                className={cn(
                  "text-white",
                  {
                    "size-[18px]": !small,
                    "size-[14px]": small,
                  },
                  iconClassName
                )}
              />
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </button>
  );
};

export default PlayButton;
