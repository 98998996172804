import { useAppSelector } from "@/redux/hooks";
import { userRolesSelector } from "@/redux/App";
import { useLocation } from "react-router";
import TabButton from "./TabButton";
import Divider from "../Divider";

// Icons
import WandIcon from "@/assets/icons/creator_dashboard/wand.svg?react";
import CreatedIcon from "@/assets/icons/creator_dashboard/created.svg?react";
import HelpIcon from "@/assets/icons/creator_dashboard/help.svg?react";
import SettingsIcon from "@/assets/icons/creator_dashboard/cog.svg?react";
// import CollectionIcon from "@/assets/icons/creator_dashboard/collection.svg?react";
// import PerformanceIcon from "@/assets/icons/creator_dashboard/stats.svg?react";
// import PayoutsIcon from "@/assets/icons/creator_dashboard/payouts.svg?react";
import UsersIcon from "@/assets/icons/creator_dashboard/users.svg?react";

const SideMenu = () => {
  const { pathname } = useLocation();
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const isCompanyAdmin =
    useAppSelector(userRolesSelector)?.includes("CompanyAdmin");
  const isCompanyMember =
    useAppSelector(userRolesSelector)?.includes("CompanyMember");

  return (
    <div className="lg:bg-tertiary-50 lg:dark:bg-tertiary-dark-gray lg:rounded-2xl overflow-clip text-center lg:pt-8 lg:pb-10">
      <div className="max-lg:w-full flex lg:flex-col lg:space-y-3 overflow-x-auto whitespace-nowrap scrollbar-hide sm:justify-center">
        <TabButton
          icon={WandIcon}
          active={pathname === "/creator-dashboard/purchases"}
          href="/creator-dashboard/purchases"
        >
          Purchased
        </TabButton>
        {isCompanyAdmin && (
          <TabButton
            icon={UsersIcon}
            active={pathname === "/creator-dashboard/team-management"}
            href="/creator-dashboard/team-management"
          >
            Company Users
          </TabButton>
        )}
        {(isCreator || isCompanyMember) && (
          <>
            {/* <TabButton
              icon={PayoutsIcon}
              active={pathname === "/creator-dashboard/payouts"}
              href="/creator-dashboard/payouts"
            >
              Payouts
            </TabButton>
            <TabButton
              icon={PerformanceIcon}
              active={pathname.startsWith("/creator-dashboard/performance")}
              href="/creator-dashboard/performance"
            >
              Performance
            </TabButton> */}
            <TabButton
              icon={CreatedIcon}
              active={pathname === "/creator-dashboard"}
              href="/creator-dashboard"
            >
              Created
            </TabButton>
          </>
        )}
        {/* <TabButton
          icon={CollectionIcon}
          active={pathname === "/creator-dashboard/collections"}
          href="/creator-dashboard/collections"
        >
          Collections
        </TabButton> */}
        <TabButton icon={HelpIcon} href="/faq" className="lg:hidden">
          Help
        </TabButton>
        <TabButton icon={SettingsIcon} href="/settings" className="lg:hidden">
          Settings
        </TabButton>
      </div>
      <Divider className="max-lg:hidden my-[16px]" />
      <ul className="space-y-3 max-lg:hidden">
        <TabButton icon={HelpIcon} href="/faq">
          Help
        </TabButton>
        <TabButton icon={SettingsIcon} href="/settings">
          Settings
        </TabButton>
      </ul>
    </div>
  );
};

export default SideMenu;
