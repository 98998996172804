import { cn } from "@/utils/utils";

interface CircleLoaderProps {
  className?: string;
  ringClassName?: string;
  progress?: number;
}

const CircleLoader = ({
  className = "",
  ringClassName = "",
  progress = 50,
}: CircleLoaderProps) => {
  return (
    <svg
      width="240"
      height="240"
      viewBox="0 0 240 240"
      color="#3f51b5"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={cn(
        "text-accent-blue dark:text-accent-main size-[50px]",
        className
      )}
    >
      <defs>
        <linearGradient id="spinner-secondHalf">
          <stop offset="0%" stopOpacity="0" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
        <linearGradient id="spinner-firstHalf">
          <stop offset="0%" stopOpacity="1" stopColor="currentColor" />
          <stop offset="100%" stopOpacity="0.5" stopColor="currentColor" />
        </linearGradient>
      </defs>

      <g strokeWidth="30">
        <path
          stroke="url(#spinner-secondHalf)"
          d="M 20 120 A 100 100 0 0 1 220 120"
        />
        <path
          stroke="url(#spinner-firstHalf)"
          d="M 220 120 A 100 100 0 0 1 20 120"
        />
        <path
          stroke="currentColor"
          strokeLinecap="round"
          d="M 20 120 A 100 100 0 0 1 20 118"
        />
      </g>

      <animateTransform
        from="0 0 0"
        to="360 0 0"
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1300ms"
      />
    </svg>
  );
};

export default CircleLoader;
