import IconWShadow from "@/components/IconWShadow";
import Modal from "@/components/Modal";

import LayersIcon from "@/assets/icons/misc/layers.svg?react";
import Button from "@/components/Button";

interface SuccessModalProps {
  open: boolean;
  onClose: () => void;
}

const SuccessModal = ({ open, onClose }: SuccessModalProps) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      className="!max-w-[646px] max-h-[443px] py-[67px] sm:py-[52px]"
    >
      <div className="text-center">
        <IconWShadow className="mb-[25px]" color="blue">
          <LayersIcon width={46} className="text-white" />
        </IconWShadow>
        <h2 className="font-medium mb-[8px]">
          Success! Bulk upload request is submitted
        </h2>
        <p className="text-tertiary mb-[27px] sm:mb-[34px]">
          Your bulk upload request is submitted! Your content will be uploaded
          within the next 48 hours
        </p>
      </div>
      <div className="flex items-center justify-center">
        <Button onClick={onClose} className="w-full sm:w-[260px]">
          Done
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal;
