import { Outlet, useLocation, useNavigation } from "react-router";
import UploadQueue from "@/features/UploadQueue";
import AudioQueue from "@/features/AudioQueue";
import BulkUploadPopup from "@/features/BulkUpload";
import PageLoader from "@/components/PageLoader";

const RootLayout = () => {
  const location = useLocation();
  const navigation = useNavigation();

  return (
    <>
      {navigation.state === "loading" && <PageLoader />}
      {location.pathname !== "/upload" && <UploadQueue />}
      <AudioQueue />
      <BulkUploadPopup />
      <Outlet />
    </>
  );
};

export default RootLayout;
