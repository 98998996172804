import { cn } from "@/utils/utils";
import WaveIcon from "@/assets/icons/media/bitrate_large.svg?react";
import PlayButton from "@/features/VideoPlayer/components/Controls/PlayButton";

interface ThumbnailProps {
  src?: string;
  playButton?: {
    isPlaying: boolean;
    onClick: () => void;
    isBuffering?: boolean;
    error?: boolean;
  };
  small?: boolean;
  className?: string;
  hidePlayButtonOnDesktop?: boolean;
}

const Thumbnail = ({
  src,
  playButton,
  small,
  className,
  hidePlayButtonOnDesktop,
}: ThumbnailProps) => {
  return (
    <div
      className={cn(
        "flex items-center justify-center bg-white dark:bg-tertiary-dark-gray rounded-md lg:rounded-xl relative size-[63px] overflow-hidden shrink-0 border border-tertiary-50 dark:border-transparent",
        {
          "lg:size-[116px]": !small,
        },
        className
      )}
    >
      {src && (
        <img
          src={src}
          alt="thumbnail"
          className={cn(
            "rounded-md w-full h-full object-cover absolute inset-0"
          )}
        />
      )}
      {!src && (
        <WaveIcon
          className={cn(
            "text-accent-blue dark:text-accent-main size-[45px] xl:size-[37px] absolute inset-0 m-auto",
            {
              "xl:size-[77px]": !small,
            }
          )}
        />
      )}
      {playButton && (
        <PlayButton
          className={cn("relative size-[35px] shrink-0", {
            "lg:hidden": small || hidePlayButtonOnDesktop,
          })}
          isPlaying={playButton.isPlaying}
          onClick={() => playButton.onClick()}
          isBuffering={playButton.isBuffering}
          alwaysVisible
          error={playButton.error}
          iconClassName="w-[14.72px] h-[12.16px]"
        />
      )}
    </div>
  );
};

export default Thumbnail;
