import { RootState } from "redux/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MinimizedAudio, Product } from "@/shared/types/product";

interface ProductState {
  isOwner: boolean;
  product: Product | null;
  viewCount: number;
  gmaps: {
    lat: number | null;
    long: number | null;
  };
  minimizedAudio?: MinimizedAudio;
}

const initialState: ProductState = {
  isOwner: false,
  product: null,
  viewCount: 0,
  gmaps: {
    lat: null,
    long: null,
  },
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    initializeProduct: (
      state,
      action: PayloadAction<{
        product: Product;
        isOwner: boolean;
      }>
    ) => {
      state.product = action.payload.product;
      state.isOwner = action.payload.isOwner;
      // Reset lat and long
      state.gmaps = initialState.gmaps;
    },
    setViewCount: (state, action: PayloadAction<number>) => {
      state.viewCount = action.payload;
    },
    setLatLong: (
      state,
      action: PayloadAction<{
        lat: number;
        long: number;
      }>
    ) => {
      const { lat, long } = action.payload;
      state.gmaps.lat = lat;
      state.gmaps.long = long;
    },
    playMinimizedAudio: (state, action: PayloadAction<MinimizedAudio>) => {
      state.minimizedAudio = action.payload;
    },
    removeMinizedAudio: (state) => {
      state.minimizedAudio = undefined;
    },
    resetProduct: (state) => {
      state.product = null;
      state.isOwner = false;
      state.viewCount = 0;
      state.gmaps = initialState.gmaps;
    },
  },
});

export const {
  setLatLong,
  setViewCount,
  initializeProduct,
  playMinimizedAudio,
  removeMinizedAudio,
  resetProduct,
} = productSlice.actions;

export const latLongSelector = (state: RootState) => state.productSlice.gmaps;
export const productSelector = (state: RootState) => state.productSlice.product;
export const viewCountSelector = (state: RootState) =>
  state.productSlice.viewCount;
export const productIdSelector = (state: RootState) =>
  state.productSlice.product?.productId;
export const isOwnerSelector = (state: RootState) => state.productSlice.isOwner;
export const minimizedAudioSelector = (state: RootState) =>
  state.productSlice.minimizedAudio;
export const isAiProductSelector = (state: RootState) =>
  Boolean(state.productSlice.product?.aiGenerated);

export default productSlice.reducer;
