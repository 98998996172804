import { Outlet } from "react-router";
import Header from "@/components/Header";
import Side from "./components/Side";
import { cn } from "@/utils/utils";

export interface SignupLayoutProps {
  creatorPage?: boolean;
}

const SignupLayout = ({ creatorPage = false }: SignupLayoutProps) => {
  return (
    <>
      <Header
        logoOnly
        searchBar={false}
        userPfPOnly
        className="absolute top-0 left-0 !pt-[24px] lg:!pt-[28px] !h-auto !px-[24px] lg:!px-[60px]"
        logoClassName="max-lg:fill-white lg:size-[51px]"
      />
      <main
        className={cn("w-full flex items-stretch dark:bg-black", {
          "gap-y-4 max-lg:flex-col lg:flex-row": creatorPage,
          "gap-[30px] md:gap-4 max-lg:flex-col lg:flex-row": !creatorPage,
        })}
      >
        <section
          className={cn(
            "w-full md:min-h-screen pb-10 flex justify-center px-[1.25rem] md:px-[3.75rem]",
            {
              "max-lg:order-2 lg:pt-[102px] lg:items-center": creatorPage,
              "max-lg:order-2 lg:pt-[130px] lg:items-center": !creatorPage,
            }
          )}
        >
          <Outlet />
        </section>
        <Side creatorPage={creatorPage} />
      </main>
    </>
  );
};

export default SignupLayout;
