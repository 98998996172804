export type User = {
  email?: string | null;
  fullName: string | null;
  userName: string | null;
  about: string | null;
  instagram: string | null;
  tiktok: string | null;
  discord: string | null;
  portfolioLink: string | null;
  profilePicture: string | null;
  userId?: string;
};

export enum KYCStatus {
  "Pending" = 1,
  "Processing" = 2,
  "Reviewing" = 3,
  "Verified" = 4,
  "Rejected" = 5,
  "Timeout" = 6,
  "Cancelled" = 7,
}

export type UserRoles =
  | "AppUser"
  | "Creator"
  | "CompanyAdmin"
  | "CompanyMember";
