import { useEffect, useState } from "react";
import { KYCStatus } from "@/shared/types/user";
import { cn } from "@/utils/utils";
import KYCBadge from "./KYCBadge";

import DefaultPfp from "@/assets/images/pfp.png";

interface DefaultAvatarProps {
  src?: string | null;
  alt?: string | null;
  className?: string;
  imgClassName?: string;
  kycStatus?: KYCStatus;
  isVisible?: boolean;
}

const DefaultAvatar = ({
  src,
  alt,
  className,
  imgClassName,
  kycStatus,
  isVisible = true,
}: DefaultAvatarProps) => {
  const [loadedSrc, setLoadedSrc] = useState<string | null>(null);

  useEffect(() => {
    if (isVisible && src) {
      setLoadedSrc(src);
    }
  }, [isVisible, src]);

  const AvatarComponent = (
    <img
      src={loadedSrc || DefaultPfp}
      className={cn(
        "size-full rounded-full object-cover border-[4px] border-[#F5F5F6] dark:border-tertiary-dark-gray mx-auto",
        imgClassName ? imgClassName : className
      )}
      alt={alt || ""}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = DefaultPfp;
      }}
      loading="lazy"
    />
  );

  if (kycStatus) {
    return (
      <div className={cn("relative", className)}>
        {AvatarComponent}
        {kycStatus && <KYCBadge kycStatus={kycStatus} />}
      </div>
    );
  }

  return AvatarComponent;
};

export default DefaultAvatar;
