import { useAudioPlayer } from "./hooks/useAudioPlayer";
import { useAppDispatch } from "@/redux/hooks";
import { formatTime } from "@/utils/helpers";
import { removeMinizedAudio } from "@/redux/Product";
import { cn } from "@/utils/utils";
import { MinimizedAudio } from "@/shared/types/product";
import { Link } from "react-router";
import Volume from "../VideoPlayer/components/Controls/Volume";
import Thumbnail from "./components/Thumbnail";
import WavePlayer from "./components/WavePlayer";
import Divider from "@/components/Divider";
import PlayButton from "../VideoPlayer/components/Controls/PlayButton";
import FavButton from "../Content/components/FavButton";
import Button from "@/components/Button";
import DeleteContent from "@/features/Content/components/DeleteContent";
import LinePlayer from "./components/LinePlayer";
import Share from "./components/Share";

import CartIcon from "@/assets/icons/cart.svg?react";
import CloseIcon from "@/assets/icons/times.svg?react";

type PlayerType = "preview" | "content" | "minimized" | "product" | "grid";

interface AudioPlayerProps {
  src?: string;
  mediaId?: string;
  trackName?: string;
  thumbnail?: string | null;
  className?: string;
  audioInfo?: MinimizedAudio;
  variant?: PlayerType;
  bottomBorder?: boolean;
  startTime?: number;
  category?: string;
  miniPlayer?: boolean;
  productId?: string;
  authorName?: string;
  authorId?: string;
  isOwner?: boolean;
  onDeleteContent?: (id: string) => void;
}

const AudioPlayer = ({
  src,
  trackName,
  thumbnail,
  variant = "content",
  bottomBorder,
  category,
  miniPlayer,
  productId,
  authorName,
  authorId,
  isOwner,
  onDeleteContent,
  className,
}: AudioPlayerProps) => {
  const dispatch = useAppDispatch();
  const {
    audioRef,
    audioData,
    isPlaying,
    audioBlob,
    loading,
    error,
    handleAudioPlay,
    handleChangeVolume,
    handleMute,
    handleChangeTime,
  } = useAudioPlayer(
    miniPlayer,
    variant === "product" || variant === "preview"
  );

  const handleCloseMiniplayer = () => {
    dispatch(removeMinizedAudio());
  };

  if (variant === "grid") {
    return (
      <div
        className={cn(
          "bg-tertiary-50 dark:bg-[#232323] rounded-2xl h-full overflow-hidden flex flex-col group",
          className
        )}
      >
        <audio className="hidden" src={src} ref={audioRef} preload="metadata" />
        <div className="bg-[radial-gradient(ellipse_at_top_left,_var(--tw-gradient-stops))] from-[#BCDCFF] dark:from-[#344002] to-[#E7E7E7] dark:to-[#2A2929] bg-[#E7E7E7] dark:bg-tertiary-dark-gray flex justify-between gap-2 px-[22px] py-[17px] h-full rounded-t-2xl">
          <div className="flex items-center gap-[10px]">
            <Thumbnail src={thumbnail ?? ""} className="!size-[72px]" small />
          </div>
          <div>
            <FavButton
              muted
              productId={productId}
              iconClassName="size-[16px] md:size-[20px]"
              className="max-md:!size-[28px] opacity-0 group-hover:opacity-100 transition-opacity duration-200"
            />
          </div>
        </div>
        <div className="pt-[29px] pb-[17px] px-[22.4px] flex items-end justify-between w-full gap-2 flex-1">
          <div className="truncate">
            <Link
              to={`/product/${productId}`}
              className="font-semibold text-lg truncate unstyled-link leading-[30px]"
            >
              {trackName}
            </Link>
            <p className="text-xs text-tertiary truncate">
              Audio by <Link to={`/user/${authorId}`}>{authorName}</Link>
            </p>
          </div>
          <div className="flex items-center gap-[8px]">
            <Volume
              className="shrink-0"
              buttonClassName="size-[32px]"
              iconClassName="size-[16px]"
              volume={audioData?.volume}
              onChangeVolume={handleChangeVolume}
              onMute={handleMute}
              isMuted={audioData?.muted}
            />
            <PlayButton
              className="relative size-[32px] shrink-0"
              isPlaying={isPlaying}
              onClick={() => handleAudioPlay()}
              isBuffering={audioData?.buffering || loading}
              alwaysVisible
              error={error}
              iconClassName="!size-[11.72px]"
              small
            />
          </div>
        </div>
      </div>
    );
  }

  if (variant === "preview") {
    return (
      <div className="bg-tertiary-50 dark:bg-[#232323] rounded-2xl flex items-center h-[89px] lg:h-[200px] px-[14px] lg:px-[24px] gap-[12px] lg:gap-[18px]">
        <Thumbnail
          src={thumbnail ?? ""}
          playButton={{
            isBuffering: audioData?.buffering || loading,
            isPlaying: isPlaying,
            onClick: () => handleAudioPlay(),
            error,
          }}
        />
        <div className="flex-1 min-w-[auto] flex flex-col gap-2 lg:gap-4 max-lg:mt-2 truncate">
          <h2 className="text-xs lg:text-[27px] font-medium lg:font-semibold leading-normal truncate">
            {trackName}
          </h2>
          <div className="flex items-center gap-2 lg:gap-6 max-lg:h-[32px]">
            <p className="text-xs text-tertiary">
              {formatTime(audioData?.duration ?? 0)}
            </p>
            <audio
              className="hidden"
              src={src}
              ref={audioRef}
              preload="metadata"
            />
            {audioBlob && (
              <WavePlayer
                audioBlob={audioBlob}
                currentTime={audioData?.currentTime}
                duration={audioData?.duration}
                handleChangeTime={(percent) => handleChangeTime(percent)}
                className="h-full"
              />
            )}
            <Volume
              className="max-lg:hidden shrink-0"
              buttonClassName="size-[24px]"
              iconClassName="size-[12px]"
              volume={audioData?.volume}
              onChangeVolume={handleChangeVolume}
              onMute={handleMute}
              isMuted={audioData?.muted}
            />
          </div>
        </div>
      </div>
    );
  }

  if (variant === "product") {
    return (
      <div className="flex items-center gap-[12px] lg:gap-[30px] w-full max-lg:fixed z-50 bottom-0 left-0 max-lg:h-[105px] max-lg:bg-tertiary-50/90 max-lg:dark:bg-[rgba(35,35,35,0.9)] max-lg:bg-opacity-90 max-lg:backdrop-blur max-lg:flex max-lg:items-center max-lg:justify-center max-lg:px-[1.25rem]">
        <div className="flex items-center gap-[14px] lg:gap-[22px]">
          <PlayButton
            className="relative size-[32px] lg:size-[36px] shrink-0"
            isPlaying={isPlaying}
            onClick={() => handleAudioPlay()}
            isBuffering={audioData?.buffering || loading}
            alwaysVisible
            error={error}
            small
          />
          <p className="max-lg:text-sm text-tertiary">
            {formatTime(audioData?.duration ?? 0)}
          </p>
        </div>
        <audio className="hidden" src={src} ref={audioRef} preload="metadata" />
        {audioBlob && (
          <WavePlayer
            audioBlob={audioBlob}
            currentTime={audioData?.currentTime}
            duration={audioData?.duration}
            handleChangeTime={(percent) => handleChangeTime(percent)}
            className="w-[70%] shrink"
            wave={{ width: 621, height: 101 }}
            thumbClassName="size-[4px] lg:size-[8px]"
          />
        )}
        <Volume
          className="shrink-0"
          buttonClassName="size-[32px] lg:size-[36px]"
          iconClassName="size-[16px] lg:size-[18px]"
          volume={audioData?.volume}
          onChangeVolume={handleChangeVolume}
          onMute={handleMute}
          isMuted={audioData?.muted}
        />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "h-[93px] lg:h-[113px] flex flex-col justify-between max-w-full",
        {
          "bg-tertiary-50 dark:bg-[#232323] !bg-opacity-90 backdrop-blur justify-center h-full":
            variant === "minimized",
        }
      )}
    >
      {variant !== "minimized" && <Divider gradient />}
      <div className="flex items-center justify-between px-[8px] lg:px-[52px] max-w-full">
        <div
          className={cn("flex items-center gap-[16px] lg:gap-[22px] truncate", {
            "max-lg:flex-1 max-lg:min-w-[auto] lg:w-1/4":
              variant !== "minimized",
          })}
        >
          <Thumbnail
            playButton={{
              isBuffering: audioData?.buffering || loading,
              isPlaying: isPlaying,
              onClick: () => handleAudioPlay(),
              error,
            }}
            src={thumbnail ?? ""}
            small
          />
          <div className="text-tertiary flex flex-col gap-[10px] lg:gap-2 max-lg:text-xs truncate">
            <Link
              to={`/product/${productId}`}
              className="truncate unstyled-link hover:underline"
            >
              {trackName}
            </Link>
            <p className="truncate">@{authorName}</p>
          </div>
        </div>
        {category && (
          <div className="text-tertiary max-lg:hidden">{category}</div>
        )}
        <div
          className={cn("flex items-center max-lg:hidden gap-4 w-[342px]", {
            "w-[512px]": variant === "minimized",
          })}
        >
          <PlayButton
            className="relative size-[32px] shrink-0"
            iconClassName="size-[12px]"
            isPlaying={isPlaying}
            onClick={() => handleAudioPlay()}
            isBuffering={audioData?.buffering || loading}
            alwaysVisible
            error={error}
            small
          />
          <p className="text-sm text-tertiary">
            {formatTime(audioData?.duration ?? 0)}
          </p>
          <audio
            className="hidden"
            src={src}
            ref={audioRef}
            preload="metadata"
          />
          <LinePlayer
            currentTime={audioData?.currentTime}
            duration={audioData?.duration}
            handleChangeTime={(percent) => handleChangeTime(percent)}
            className="shrink"
          />
          <Volume
            className="max-lg:hidden shrink-0"
            buttonClassName="size-[32px]"
            iconClassName="size-[16px]"
            volume={audioData?.volume}
            onChangeVolume={handleChangeVolume}
            onMute={handleMute}
            isMuted={audioData?.muted}
          />
        </div>
        <div className="flex items-center">
          {isOwner ? (
            <DeleteContent
              productId={productId}
              onDelete={() => onDeleteContent?.(productId ?? "")}
              muted
            />
          ) : (
            <FavButton muted productId={productId} />
          )}
          {!isOwner && (
            <Button
              variant="transparent-inverted"
              tiny
              href={`/product/${productId}`}
            >
              <CartIcon className="text-tertiary" />
            </Button>
          )}
          {variant === "minimized" && (
            <Button
              variant="transparent-inverted"
              tiny
              onClick={() => handleCloseMiniplayer()}
            >
              <CloseIcon className="text-tertiary" />
            </Button>
          )}
        </div>
        {variant !== "minimized" && (
          <div>
            <Share productId={productId} title={trackName} />
          </div>
        )}
      </div>
      {bottomBorder ? <Divider gradient /> : <div></div>}
    </div>
  );
};

export default AudioPlayer;
