import { cartItemsSelector } from "@/redux/Cart";
import { useAppSelector } from "@/redux/hooks";
import { formatPrice } from "@/utils/helpers";
import { currencySelector } from "@/redux/App";
import { Link } from "react-router";
import Divider from "@/components/Divider";
import CartImage from "../../Cart/CartImage";
import DeleteButton from "@/components/Cart/DeleteButton";

const CartItems = () => {
  const items = useAppSelector(cartItemsSelector);
  const currency = useAppSelector(currencySelector);

  if (items?.length === 0) {
    return <></>;
  }

  return (
    <>
      {items?.map((item, index) => (
        <div key={index}>
          <div className="flex gap-2 overflow-hidden">
            <CartImage
              src={(item?.thumbnail ?? "") || item.fileUrl}
              isAudio={item.type?.split("/")?.[0] === "audio"}
              title={item.title || "Product"}
            />
            <div className="space-y-0.5 flex-1 min-w-[auto] overflow-hidden">
              <div className="flex justify-between items-start w-full">
                <Link
                  to={`/product/${item.productId}`}
                  className="unstyled-link font-medium text-sm"
                >
                  {item.title}
                </Link>
                <DeleteButton
                  cartItemId={item.cardItemId}
                  name={item.title || "Product"}
                  className="shrink-0"
                />
              </div>
              <div className="flex items-center gap-[6px] text-tertiary text-xs">
                <p className="capitalize">{item.type?.split("/")?.[0]}</p>
                {/* <span className="size-[2px] rounded-full bg-tertiary-gray dark:bg-white"></span> */}
              </div>
              <p className="font-medium text-xs pt-[11px]">
                {currency?.currency || "AED"}{" "}
                {formatPrice((item.price ?? 0) * (currency?.rate || 1))}
              </p>
            </div>
          </div>
          {index !== items.length - 1 && (
            <Divider gradient={true} className="mt-[10px] mb-[18px]" />
          )}
        </div>
      ))}
    </>
  );
};

export default CartItems;
