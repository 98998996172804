import { SortOptions } from "@/shared/types/product";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

interface SearchState {
  sort: SortOptions;
}

const initialState: SearchState = {
  sort: "date",
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    setSort: (state, action: PayloadAction<SortOptions>) => {
      state.sort = action.payload;
    },
  },
});

export const { setSort } = searchSlice.actions;

export const sortSelector = (state: RootState) => state.searchSlice.sort;

export default searchSlice.reducer;
