import { formatPrice, getEnumKeyByEnumValue } from "@/utils/helpers";
import { Link } from "react-router";
import { Product, ProductType } from "@/shared/types/product";
import CartImage from "@/components/Cart/CartImage";
import DownloadInvoiceButton from "./DownloadInvoiceButton";

interface OrderTableProps {
  products: Product[];
  currency: string;
  rate: number;
  orderId?: string;
}

const OrderTable = ({ products, currency, rate, orderId }: OrderTableProps) => {
  return (
    <div className="space-y-4">
      <div className="bg-tertiary-50 dark:bg-tertiary-dark-gray rounded-xl py-4 px-4 lg:px-8 grid grid-cols-2 lg:grid-cols-[112px,2.4fr,1.2fr,1.2fr,1.2fr,1fr,1fr] items-center gap-3">
        <div className="text-xs font-medium uppercase">Content</div>
        <div className="text-xs font-medium uppercase max-lg:hidden">Title</div>
        <div className="text-xs font-medium uppercase max-lg:hidden">Price</div>
        <div className="text-xs font-medium uppercase max-lg:hidden">
          Asset #ID
        </div>
        <div className="text-xs font-medium uppercase max-lg:hidden">
          Format
        </div>
        <div className="text-xs font-medium uppercase max-lg:hidden">
          Licensing
        </div>
        <div className="text-xs text-end">
          <DownloadInvoiceButton orderId={orderId} />
        </div>
      </div>

      {products.map((prod) => {
        const {
          productId,
          name,
          type,
          typeWithExtension,
          fileUrl,
          thumbnailUrl,
          sku,
          price,
          license,
          creatorName,
        } = prod;
        const productType = typeWithExtension?.split("/")?.[0];

        return (
          <div
            key={productId}
            className="px-[12px] lg:px-8 grid grid-cols-[100px,1fr] lg:grid-cols-[112px,2.4fr,1.2fr,1.2fr,1.2fr,1fr,1fr] items-center lg:gap-3"
          >
            <CartImage
              src={thumbnailUrl || fileUrl || ""}
              title={name ?? ""}
              className="w-[90px] lg:w-[100px] h-[70px] rounded-md"
              isAudio={productType === "audio"}
            />
            <div className="flex flex-col gap-2 truncate">
              <Link
                to={`/product/${productId}`}
                className="text-sm text-[#202224] dark:text-[#D6D2D2] truncate unstyled-link hover:underline"
              >
                {name}
              </Link>
              <div className="text-xs text-tertiary">
                <span className="max-lg:hidden">@{creatorName}</span>
                <span className="lg:hidden">
                  {currency || "AED"} {formatPrice((price ?? 0) * rate)}
                </span>
              </div>
              <div className="text-xs lg:hidden flex items-center justify-between gap-2">
                <span className="text-accent-blue dark:text-accent-main underline">
                  #{sku}
                </span>
                <span className="text-xs text-tertiary">
                  {license === "0" ? "Commercial" : "Editorial"}
                </span>
              </div>
            </div>
            <div className="text-sm text-[#202224] dark:text-[#D6D2D2] max-lg:hidden">
              {currency || "AED"} {formatPrice((price ?? 0) * rate)}
            </div>
            <Link
              to={`/product/${productId}`}
              className="text-sm font-normal underline max-lg:hidden"
            >
              #{sku}
            </Link>
            <div className="text-sm text-[#202224] dark:text-[#D6D2D2] max-lg:hidden capitalize">
              {getEnumKeyByEnumValue(ProductType, type ?? "0")}
            </div>
            <div className="text-sm text-[#202224] dark:text-[#D6D2D2] max-lg:hidden">
              {license === "0" ? "Commercial" : "Editorial"}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OrderTable;
