import { useState } from "react";
import {
  isDarkModeSelector,
  isLoggedInSelector,
  logout,
  themeSelector,
  userInfoSelector,
  userRolesSelector,
} from "@/redux/App";
import clsx from "clsx";
import { resetCart } from "@/redux/Cart";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { Link, useNavigate } from "react-router";
import Toggle from "../Toggle";
import Button from "../Button";
import LogoutModal from "./UserDropdown/LogoutModal";
import Divider from "../Divider";
import DefaultAvatar from "../Avatar/DefaultAvatar";
import MobileCurrencyPicker from "./CurrencyPicker/MobileCurrencyPicker";

// Icons
import { IoMoonOutline } from "react-icons/io5";
import { LuSunDim } from "react-icons/lu";
import LinkIcon from "@/assets/icons/external_link.svg?react";

const Menu = () => {
  const theme = useAppSelector((state) => themeSelector(state));
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));
  const [logoutModal, showLogoutModal] = useState(false);
  const userInfo = useAppSelector((state) => userInfoSelector(state));
  const isDarkMode = useAppSelector(isDarkModeSelector);
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const navigate = useNavigate();

  const handleToggleTheme = () => {
    dispatch({
      type: "app/toggleTheme",
      payload:
        theme === "dark" ||
        (theme === "system" &&
          window.matchMedia("(prefers-color-scheme: dark)").matches)
          ? "light"
          : "dark",
    });
  };

  const handleSignOut = () => {
    dispatch(logout());
    dispatch(resetCart());
    showLogoutModal(false);
    navigate("/");
  };

  const handleLogoutClick = () => {
    showLogoutModal(true);
  };

  return (
    <>
      <LogoutModal
        open={logoutModal}
        onClose={() => showLogoutModal(false)}
        onLogout={handleSignOut}
      />
      <div className="space-y-6 relative">
        <div className="px-[32px]">
          <Toggle
            className="flex justify-between ps-4 pe-8"
            pillClassName={clsx({
              "!bg-accent-blue": !isDarkMode,
            })}
            active={
              theme === "dark" ||
              (theme === "system" &&
                window.matchMedia("(prefers-color-scheme: dark)").matches)
            }
            onChange={handleToggleTheme}
            label={
              <div className="flex items-center gap-3">
                {isDarkMode ? (
                  <IoMoonOutline size={24} />
                ) : (
                  <LuSunDim size={24} />
                )}
                <span className="text-sm font-medium">
                  {isDarkMode ? "Dark" : "Light"} Mode
                </span>
              </div>
            }
          />
        </div>
        {isLoggedIn ? (
          <div className="space-y-5">
            <div className="px-[32px]">
              <DefaultAvatar
                src={userInfo.profilePicture}
                className="border-none size-14"
              />
              <h4 className="font-semibold mx-4 text-center text-[20px]">
                {userInfo.fullName}
              </h4>
              {!isCreator && (
                <Button
                  href="/become-creator"
                  variant="blue"
                  className="mx-4 max-[346px]:text-xs max-[346px]:!px-1 mt-2"
                >
                  Become a creator
                </Button>
              )}
            </div>
            <div>
              <div className="px-[32px]">
                <Divider gradient={true} className="mt-[30px] mb-[22px]" />
              </div>
              <ul className="space-y-[14px]">
                <li>
                  <Link
                    to={`/creator-dashboard${!isCreator ? "/purchases" : ""}`}
                    className="unstyled-link text-sm font-medium px-[52px]"
                  >
                    My Profile
                  </Link>
                </li>
                {isCreator && (
                  <li>
                    <Link
                      to="/upload"
                      className="unstyled-link text-sm font-medium px-[52px]"
                    >
                      Upload content
                    </Link>
                  </li>
                )}
                <li>
                  <Link
                    to="/invite-friends"
                    className="unstyled-link text-sm font-medium px-[52px]"
                  >
                    Invite Friends
                  </Link>
                </li>
                <li>
                  <Link
                    to="/settings"
                    className="unstyled-link text-sm font-medium px-[52px]"
                  >
                    Settings
                  </Link>
                </li>
                {isLoggedIn && (
                  <>
                    <li>
                      <Link
                        to="/cart"
                        className="unstyled-link text-sm font-medium px-[52px]"
                      >
                        My Cart
                      </Link>
                    </li>
                    <li>
                      <MobileCurrencyPicker />
                    </li>
                  </>
                )}
              </ul>
              <div className="px-[32px]">
                <Divider gradient={true} className="mt-5 mb-6" />
              </div>
              <ul className="space-y-4 ps-[52px] pe-[62px]">
                <li>
                  <Link
                    to="/faq"
                    className="flex justify-between items-center unstyled-link text-sm font-medium"
                  >
                    Help Center <LinkIcon className="text-tertiary" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/terms-of-use"
                    className="flex justify-between items-center unstyled-link text-sm font-medium"
                  >
                    Terms of Use <LinkIcon className="text-tertiary" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="/content-guidelines"
                    className="flex justify-between items-center unstyled-link text-sm font-medium"
                  >
                    Content Guidelines <LinkIcon className="text-tertiary" />
                  </Link>
                </li>
              </ul>
              <div className="px-[32px]">
                <Divider gradient={true} className="mt-[26px] mb-[22px]" />
              </div>
              <div className="px-[52px]">
                <Button
                  variant="link-unstyled"
                  onClick={handleLogoutClick}
                  className="text-sm !px-0"
                >
                  Sign Out
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="space-y-4 px-[32px]">
            <Button href="/sign-in">Sign In</Button>
            <Button href="/sign-up">Sign Up</Button>
          </div>
        )}
      </div>
    </>
  );
};

export default Menu;
