import { Fragment } from "react";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { AnimatePresence, motion } from "framer-motion";
import { useAppSelector } from "@/redux/hooks";
import { cartPriceSelector } from "@/redux/Cart";
import { currencySelector } from "@/redux/App";
import { twMerge } from "tailwind-merge";
import CartButton from "./CartButton";
import Button from "@/components/Button";
import CartItems from "./CartItems";
import CheckoutButton from "./CheckoutButton";
import { formatPrice } from "@/utils/helpers";

interface CartProps {
  className?: string;
}

const Cart = ({ className }: CartProps) => {
  const currency = useAppSelector(currencySelector);
  const price = useAppSelector(cartPriceSelector);

  return (
    <Popover as={Fragment}>
      {({ open }) => (
        <div className={twMerge("relative", className)}>
          <PopoverButton as={CartButton}></PopoverButton>
          {open && (
            <div
              className="absolute w-0 h-0 top-[108%] left-1/2 -translate-x-1/2
              border-l-[12px] border-l-transparent
              border-b-[14px] border-b-white dark:border-b-tertiary-dark-gray
              border-r-[12px] border-r-transparent"
            ></div>
          )}
          <AnimatePresence>
            {open && (
              <PopoverPanel
                static
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                anchor="bottom"
                className="[--anchor-offset:-92px] [--anchor-gap:16px] z-50 !w-[361px] bg-white dark:bg-tertiary-dark-gray rounded-2xl shadow-lg origin-top"
              >
                <div className="max-h-[396px] overflow-y-auto space-y-3 ps-4 pe-2 py-3 scrollbar-thumb-rounded-full scrollbar-track-rounded-full scrollbar scrollbar-w-[6px] scrollbar-thumb-[#d9d9d9] dark:scrollbar-thumb-[#5C5B5B] hover:scrollbar-thumb-[#5C5B5B] scrollbar-track-transparent me-1 mt-3">
                  <CartItems />
                </div>
                <div className="px-4 pt-[14px] pb-9 shadow-[0px_4px_20px_0px_rgba(101,99,99,0.18)] dark:shadow-[0px_4px_20px_0px_rgba(185,185,185,0.18)]">
                  <div className="w-full flex items-center justify-between mb-[21px] pe-[10px]">
                    <h4 className="text-base">Total</h4>
                    <h4 className="text-base">
                      {currency?.currency || "AED"}{" "}
                      {formatPrice(price.finalPrice * (currency?.rate || 1))}
                    </h4>
                  </div>
                  <CheckoutButton />
                  <Button
                    href="/cart"
                    variant="link"
                    className="text-tertiary w-full text-sm"
                  >
                    View cart
                  </Button>
                </div>
              </PopoverPanel>
            )}
          </AnimatePresence>
        </div>
      )}
    </Popover>
  );
};

export default Cart;
