import { useTranslation } from "react-i18next";
import config from "@/config.json";

// Components
import Logo from "@/components/Logo";
import MenuLink from "./MenuLink";
import SocialMediaLink from "./SocialMediaLink";

// Icons
import { FaInstagram, FaTiktok } from "react-icons/fa6";

interface FooterProps {
  className?: string;
}

const Footer = ({ className = "" }: FooterProps) => {
  const { t } = useTranslation();

  return (
    <footer
      className={`space-y-6 pt-20 pb-10 container max-w-full ${className}`}
    >
      <section className="flex max-xl:flex-col gap-10">
        <div className="w-full xl:w-1/3 xl:pr-28">
          <Logo size={51} beta={false} />
          <p className="text-tertiary-main mt-4 text-sm">{t("footer.about")}</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-10 md:gap-16 xl:gap-40 w-full">
          <div className="space-y-4">
            <p className="text-sm font-medium">{t("footer.company.title")}</p>
            <ul className="flex flex-col gap-2">
              <li>
                <MenuLink to="/about-us">
                  {t("footer.company.links.about")}
                </MenuLink>
              </li>
              <li>
                <MenuLink to="/privacy-policy">
                  {t("footer.company.links.privacy")}
                </MenuLink>
              </li>
              <li>
                <MenuLink to="/payouts">Payouts</MenuLink>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <p className="text-sm font-medium">{t("footer.help.title")}</p>
            <ul className="flex flex-col gap-2">
              <li>
                <MenuLink to="/faq">{t("footer.help.links.faq")}</MenuLink>
              </li>
              <li>
                <MenuLink to="mailto:support@mo7tawa.me">
                  {t("footer.help.links.contact")}
                </MenuLink>
              </li>
              <li>
                <MenuLink to="https://mo7tawa.atlassian.net/servicedesk/customer/portal/1">
                  {t("footer.help.links.support")}
                </MenuLink>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <p className="text-sm font-medium">{t("footer.careers.title")}</p>
            <ul className="flex flex-col gap-2">
              <li>
                <MenuLink to="/become-creator">
                  {t("footer.careers.links.join_creator")}
                </MenuLink>
              </li>
            </ul>
          </div>
          <div className="space-y-4">
            <p className="text-sm font-medium">{t("footer.community.title")}</p>
            <ul className="flex flex-col gap-4">
              <li>
                <SocialMediaLink
                  icon={FaInstagram}
                  href={config.socialmedia.instagram}
                >
                  {t("footer.community.links.ig")}
                </SocialMediaLink>
              </li>
              <li>
                <SocialMediaLink
                  icon={FaTiktok}
                  href={config.socialmedia.tiktok}
                >
                  {t("footer.community.links.tk")}
                </SocialMediaLink>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="pt-6 border-t dark:border-gray-800 flex max-sm:flex-wrap items-center justify-center gap-4 sm:gap-10">
        <MenuLink to="/privacy-policy" smallText={false}>
          {t("sub_footer.privacy")}
        </MenuLink>
        <MenuLink to="/content-guidelines" smallText={false}>
          {t("sub_footer.content_guidelines")}
        </MenuLink>
        <MenuLink to="/terms-of-use" smallText={false}>
          {t("sub_footer.terms")}
        </MenuLink>
      </section>
    </footer>
  );
};

export default Footer;
