import Button from "../Button";
import IconWShadow from "../IconWShadow";
import Modal from "../Modal";

import TrashIcon from "@/assets/icons/trash.svg?react";

interface CancelModalProps {
  show: boolean;
  onClose: () => void;
  onNext: () => void;
}

const CancelModal = ({ show, onClose, onNext }: CancelModalProps) => {
  const handleOnNext = () => {
    onNext();
    onClose();
  };

  return (
    <Modal
      open={show}
      onClose={onClose}
      className="w-[320px] sm:w-[462px] h-[393px] sm:h-[384px] flex flex-col items-center sm:p-0 !pt-[72px]"
    >
      <IconWShadow className="mb-5" color="red">
        <TrashIcon className="text-white size-6" />
      </IconWShadow>
      <div className="text-center flex flex-col gap-2 sm:gap-3 items-center max-sm:w-full">
        <h4 className="font-semibold">Cancel upload</h4>
        <p className="text-xs sm:text-sm text-tertiary sm:max-w-[80%] mx-auto">
          Are you sure you want to cancel this video upload?
        </p>
        <div className="flex max-sm:flex-col w-full justify-center items-center gap-4 mt-8 sm:mt-[27px]">
          <Button
            className="max-sm:w-full !px-2 max-sm:order-2"
            variant="link-unstyled"
            onClick={onClose}
          >
            Back
          </Button>
          <Button
            className="w-full sm:w-[173px] h-[52px]"
            onClick={() => handleOnNext()}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelModal;
