import {
  companySetupSelector,
  isLoggedInSelector,
  userInfoSelector,
  userRolesSelector,
} from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import { KYCStatus } from "@/shared/types/user";
import { Navigate, useLocation } from "react-router";

interface AuthRouteProps {
  element: JSX.Element;
  creatorPage?: boolean;
  companyAdminPage?: boolean;
  condition?: boolean;
  kycOnly?: boolean;
}

const AuthRoute: React.FC<AuthRouteProps> = ({
  element,
  creatorPage = false,
  companyAdminPage,
  condition = true,
  kycOnly,
}) => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const isCompanyAdmin =
    useAppSelector(userRolesSelector)?.includes("CompanyAdmin");
  const userInCompany =
    useAppSelector(userRolesSelector)?.includes("CompanyMember");
  const companySetup = useAppSelector(companySetupSelector);
  const setupCategories = useAppSelector(userInfoSelector)?.categorySetup;
  const kycStatus = useAppSelector(userInfoSelector)?.kycStatus;
  const location = useLocation();

  // If company admin and company not setup yet:
  if (
    !companySetup &&
    isCompanyAdmin &&
    location.pathname !== "/company-info"
  ) {
    return <Navigate to="/company-info" replace />;
  }

  if (companyAdminPage && !isCompanyAdmin) return <Navigate to="/" replace />;

  if (creatorPage) {
    if (!isCreator && !userInCompany) {
      return <Navigate to="/" replace />;
    }
    if (isCreator && !userInCompany && !setupCategories) {
      return <Navigate to="/become-creator" replace />;
    }
    if (
      isCreator &&
      kycOnly &&
      kycStatus !== KYCStatus.Verified &&
      !userInCompany
    ) {
      return <Navigate to="/verification" replace />;
    }
  }

  return isLoggedIn && condition ? (
    element
  ) : (
    <Navigate to={`/sign-in/?target=${location.pathname}`} replace />
  );
};

export default AuthRoute;
