import { useEffect, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { cn } from "@/utils/utils";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import ReactSlider from "react-slider";

import VolumeHighIcon from "@/assets/icons/video/volume_high.svg?react";
import VolumeMutedIcon from "@/assets/icons/video/volume_muted.svg?react";

interface VolumeProps {
  volume?: number;
  onChangeVolume?: (volume: number) => void;
  onMute?: () => void;
  isMuted?: boolean;
  isPointer?: boolean;
  className?: string;
  buttonClassName?: string;
  iconClassName?: string;
}

const Volume = ({
  volume,
  onChangeVolume,
  onMute,
  isMuted,
  isPointer,
  className,
  buttonClassName,
  iconClassName,
}: VolumeProps) => {
  const [volumeSlider, showVolumeSlider] = useState(false);
  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout>();

  const handleMouseEnter = () => {
    showVolumeSlider(true);
    if (closeTimeout) clearTimeout(closeTimeout);
  };

  const handleMouseLeave = (ms = 300) => {
    const timeout = setTimeout(() => showVolumeSlider(false), ms);
    setCloseTimeout(timeout);
  };

  const handleToggleOnHotkey = () => {
    showVolumeSlider(true);
    handleMouseLeave(1000);
  };

  const handleOnMute = () => {
    if (isPointer) {
      if (volumeSlider) {
        onMute?.();
      } else {
        showVolumeSlider(true);
      }
      return;
    }
    onMute?.();
  };

  // When hotkeys are pressed, show slider
  useHotkeys("up, down", () => handleToggleOnHotkey());

  useEffect(() => {
    return () => clearTimeout(closeTimeout);
  }, [closeTimeout]);

  return (
    <Popover className={cn("relative", className)}>
      {({ open }) => (
        <>
          <PopoverButton
            className={cn(
              "size-8 rounded-full bg-tertiary-925/45 backdrop-blur flex items-center justify-center",
              buttonClassName
            )}
            onClick={() => handleOnMute()}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
            onFocus={() => handleMouseEnter()}
            onBlur={() => handleMouseLeave()}
          >
            {!isMuted ? (
              <VolumeHighIcon className={cn("text-white", iconClassName)} />
            ) : (
              <VolumeMutedIcon className={cn("text-white", iconClassName)} />
            )}
          </PopoverButton>
          <AnimatePresence>
            {volumeSlider && !isPointer && (
              <PopoverPanel
                static
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                anchor="top"
                className="[--anchor-gap:12px] bg-tertiary-925/45 backdrop-blur rounded-2xl flex flex-col items-center justify-center gap-2 text-white origin-bottom absolute bottom-[130%] left-1/2 -translate-x-1/2 z-50 w-[40px] h-[166px]"
                onMouseEnter={() => handleMouseEnter()}
                onMouseLeave={() => handleMouseLeave()}
                onFocus={() => handleMouseEnter()}
                onBlur={() => handleMouseLeave()}
              >
                <div className="flex flex-col gap-1 min-h-[120px]">
                  <div className="font-bold text-lg leading-[14px] text-center">
                    <p>+</p>
                    <p>-</p>
                  </div>
                  <ReactSlider
                    className="relative w-full h-[100px] flex items-center justify-center"
                    thumbClassName="size-[16px] rounded-full bg-accent-blue dark:bg-accent-main"
                    trackClassName="w-[4px] rounded bg-white"
                    orientation="vertical"
                    invert
                    pearling
                    value={(volume ?? 0) * 100}
                    onChange={(value) => onChangeVolume?.(value / 100)}
                  />
                </div>
              </PopoverPanel>
            )}
          </AnimatePresence>
        </>
      )}
    </Popover>
  );
};

export default Volume;
