import { twMerge } from "tailwind-merge";
import { FunctionComponent, SVGProps, forwardRef } from "react";
import { FieldError } from "react-hook-form";
import { IconType } from "react-icons";
import { cn } from "@/utils/utils";

import { IoCloseCircleSharp } from "react-icons/io5";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement> {
  icon?: IconType | FunctionComponent<SVGProps<SVGSVGElement>>;
  iconEnd?: IconType | FunctionComponent<SVGProps<SVGSVGElement>>;
  iconEndOnClick?: () => void;
  iconClassName?: string;
  iconSize?: number;
  label?: string | React.ReactNode;
  helpText?: string;
  error?: FieldError | string;
  showErrorText?: boolean;
  hideErrorIcon?: boolean;
  errorClassName?: string;
  textarea?: boolean;
  hideNbArrows?: boolean;
  tooltip?: string;
  containerClassName?: string;
  labelClassName?: string;
  divClassName?: string;
  optional?: boolean;
  counter?: number;
}

const Input = forwardRef(
  (
    {
      icon: InputIcon,
      iconEnd: InputIconEnd,
      iconEndOnClick,
      containerClassName = "",
      labelClassName = "",
      iconClassName = "",
      iconSize = 24,
      label,
      helpText,
      error,
      showErrorText,
      errorClassName,
      textarea = false,
      hideNbArrows = false,
      divClassName = "",
      tooltip,
      optional = false,
      hideErrorIcon,
      counter,
      ...props
    }: InputProps,
    ref: any
  ) => {
    const InputComponent = textarea ? (
      <>
        <textarea
          {...props}
          className={cn(
            "rounded-2xl h-[10rem] bg-tertiary-50 dark:bg-tertiary-950 placeholder:text-tertiary-main disabled:bg-[#EAE8E8] disabled:dark:bg-tertiary-900 disabled:cursor-not-allowed transition duration-200 focus:ring-2 ring-accent-blue dark:ring-accent-main px-5 py-[0.875rem] w-full outline-none resize-none",
            props.className,
            {
              "ps-12": InputIcon,
              "pe-12": InputIconEnd,
              "ring-1 ring-[#F52C1F] dark:ring-[#F96158]": error,
            }
          )}
          ref={ref}
        ></textarea>
        {(counter || counter === 0) && props.maxLength ? (
          <span className="text-sm text-tertiary-main absolute bottom-[14px] end-[20px]">
            {counter === 0 ? "" : counter}/{props.maxLength}
          </span>
        ) : (
          <></>
        )}
      </>
    ) : (
      <>
        <input
          {...props}
          className={cn(
            "rounded-full bg-tertiary-50 dark:bg-tertiary-950 placeholder:text-tertiary-main disabled:bg-[#EAE8E8] disabled:dark:bg-tertiary-900 disabled:cursor-not-allowed transition duration-200 focus:ring-2 ring-accent-blue dark:ring-accent-main px-5 py-[0.875rem] w-full outline-none",
            props.className,
            {
              "ps-12": InputIcon,
              "pe-12": InputIconEnd,
              "[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none":
                hideNbArrows,
              "ring-1 ring-[#F52C1F] dark:ring-[#F96158]": error,
            }
          )}
          ref={ref}
        />
        {error && !hideErrorIcon && (
          <IoCloseCircleSharp
            className={cn(
              "text-[#F52C1F] dark:text-[#F96158] absolute end-[0.875rem] size-4",
              {
                "top-1/2 -translate-y-1/2": !showErrorText,
                "top-[11px]": showErrorText,
              }
            )}
          />
        )}
      </>
    );

    const InputIconEndComponent = InputIconEnd && (
      <InputIconEnd
        size={iconSize}
        className={`absolute top-4 end-4 ${iconClassName}`}
        width={iconSize}
      />
    );

    const InputWIconComponent = (InputIcon || InputIconEnd) && (
      <>
        {InputIcon && (
          <InputIcon
            size={iconSize}
            className={`absolute inset-4 ${iconClassName}`}
            width={iconSize}
          />
        )}
        {InputComponent}
        {InputIconEnd && !error ? (
          iconEndOnClick ? (
            <button onClick={iconEndOnClick} type="button">
              {InputIconEndComponent}
            </button>
          ) : (
            InputIconEndComponent
          )
        ) : (
          ""
        )}
      </>
    );

    const FinalComponent =
      InputIcon || InputIconEnd ? InputWIconComponent : InputComponent;

    return (
      <label
        className={twMerge("flex flex-col gap-2", containerClassName || "")}
        htmlFor={props.id}
      >
        {label &&
          (typeof label === "string" ? (
            <span
              className={twMerge(
                "ps-3 sm:ps-[18px] text-sm",
                labelClassName || ""
              )}
            >
              {label}{" "}
              {optional && <span className="text-tertiary">(Optional)</span>}
            </span>
          ) : (
            label
          ))}
        <div
          className={cn(
            "relative w-full h-full flex flex-col group",
            divClassName
          )}
        >
          {showErrorText ? (
            <div className="space-y-2">
              {FinalComponent}
              {showErrorText && error && (
                <p
                  className={cn(
                    "text-xs ps-3 sm:ps-[18px] text-error-default dark:text-error-dark-default",
                    errorClassName
                  )}
                >
                  {typeof error !== "string" && error?.message}
                </p>
              )}
            </div>
          ) : (
            FinalComponent
          )}

          {tooltip && (
            <div className="hidden group-hover:flex absolute text-sm px-4 py-2 bg-black text-white rounded-xl top-full max-md:translate-y-2 md:top-1/2 md:-translate-y-1/2 md:start-full md:translate-x-2 md:w-max md:max-w-sm transition-all duration-150 ease-linear">
              {tooltip}
            </div>
          )}
        </div>
        {helpText && (
          <span className="ps-6 text-sm text-tertiary-main text-start">
            {helpText}
          </span>
        )}
      </label>
    );
  }
);

export default Input;
