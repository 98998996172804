import { useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { useAppDispatch } from "@/redux/hooks";
import { useQueryClient } from "@tanstack/react-query";
import { removeProduct } from "@/redux/Cart";
import toast from "react-hot-toast";
import Button from "@/components/Button";
import Modal from "../Modal";
import IconWShadow from "../IconWShadow";

import TrashIcon from "@/assets/icons/trash.svg?react";

interface DeleteButtonProps {
  cartItemId: string;
  name: string;
  className?: string;
}

const DeleteButton = ({ cartItemId, name, className }: DeleteButtonProps) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { sendRequest, loading } = useRequest();
  const [modal, showModal] = useState(false);

  const handleRemoveFromCart = async () => {
    try {
      await sendRequest({
        method: "DELETE",
        url: `ShoppingCart/${cartItemId}`,
      });
      dispatch(removeProduct(cartItemId));
      showModal(false);
      // Invalidate cart
      queryClient.refetchQueries({
        queryKey: ["cart"],
      });
      toast.success(
        <p>
          <strong>{name}</strong> has been removed from your cart.
        </p>
      );
    } catch (error) {
      toast.error(
        "This item couldn't be removed from your cart. Please try again later."
      );
    }
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={() => showModal(false)}
        className="w-[20rem] h-[24.563rem] sm:w-[28.875rem] sm:h-[24rem] flex flex-col items-center sm:p-0 !pt-[72px]"
      >
        <IconWShadow className="mb-5" color="red">
          <TrashIcon className="text-white size-[1.5rem]" />
        </IconWShadow>
        <div className="text-center flex flex-col gap-2 sm:gap-3 items-center max-sm:w-full">
          <h4 className="font-semibold">Remove from Cart</h4>
          <p className="text-xs sm:text-sm text-tertiary max-w-[70%]">
            Are you sure you want to remove this item from your cart?
          </p>
          <div className="flex max-sm:flex-col items-center mt-7 max-sm:w-full">
            <Button
              className="max-sm:w-full sm:!ps-2 sm:pe-5 max-sm:order-2 after:!hidden hover:!text-black dark:hover:!text-white"
              onClick={() => showModal(false)}
              variant="transparent"
            >
              Back
            </Button>
            <Button
              className="max-sm:!w-full sm:w-[10.813rem] h-[3.25rem]"
              onClick={handleRemoveFromCart}
              loading={loading}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        type="button"
        onClick={() => showModal(true)}
        variant="link-unstyled"
        loading={loading}
        className={className || ""}
      >
        <TrashIcon className="size-5 text-tertiary" />
      </Button>
    </>
  );
};

export default DeleteButton;
