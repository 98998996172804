import { useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import Modal from "@/components/Modal";
import IconWShadow from "@/components/IconWShadow";
import Button from "@/components/Button";

import TrashIcon from "@/assets/icons/trash.svg?react";
import { cn } from "@/utils/utils";

interface DeleteContentProps {
  productId?: string;
  onDelete?: () => void;
  muted?: boolean;
  className?: string;
}

const DeleteContent = ({
  productId,
  onDelete,
  muted,
  className,
}: DeleteContentProps) => {
  const { sendRequest, loading } = useRequest();
  const [modal, showModal] = useState(false);

  const handleModalClose = () => {
    showModal(false);
  };

  const handleDeleteContent = async () => {
    if (onDelete) {
      onDelete();
    }
    try {
      await sendRequest({
        method: "DELETE",
        url: `Products/${productId}`,
      });
      handleModalClose();
    } catch (error) {}
  };

  return (
    <>
      <Modal
        open={modal}
        onClose={handleModalClose}
        className="w-[20rem] h-[24.563rem] sm:w-[28.875rem] sm:h-[24rem] flex flex-col items-center sm:p-0 !pt-[72px]"
      >
        <IconWShadow className="mb-5" color="red">
          <TrashIcon className="text-white size-[1.5rem]" />
        </IconWShadow>
        <div className="text-center flex flex-col gap-2 sm:gap-3 items-center max-sm:w-full">
          <h4 className="font-semibold">Delete content</h4>
          <p className="text-xs sm:text-sm text-tertiary max-w-[70%]">
            Are you sure you want to delete this asset permanently?
          </p>
          <div className="flex max-sm:flex-col items-center mt-7 max-sm:w-full">
            <Button
              className="max-sm:w-full sm:!ps-2 sm:pe-5 max-sm:order-2 after:!hidden hover:!text-black dark:hover:!text-white"
              onClick={handleModalClose}
              variant="transparent"
            >
              Back
            </Button>
            <Button
              className="max-sm:!w-full sm:w-[10.813rem] h-[3.25rem]"
              onClick={handleDeleteContent}
              disabled={loading}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
      <Button
        icon={TrashIcon}
        iconClassName={cn("size-5", {
          "text-tertiary": muted,
        })}
        tiny={true}
        variant={muted ? "transparent-inverted" : "default-dimmed"}
        onClick={() => showModal(true)}
        className={className}
      />
    </>
  );
};

export default DeleteContent;
