import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import "../configs/axiosConfig";
import { store } from "@/redux/store";

type RequestParams = AxiosRequestConfig & {
  external?: boolean;
};

export const sendRequest = async <T = any>(
  params: RequestParams
): Promise<AxiosResponse<T>> => {
  const userToken = store.getState().appSlice.user.token;
  const abortController = new AbortController();
  try {
    const { url, external = false, ...axiosConfig } = params;

    const response = await axios({
      ...axiosConfig,
      signal: abortController.signal,
      url: external
        ? url
        : (import.meta.env.VITE_BACKEND_URL ?? "http://localhost:3000") +
          import.meta.env.VITE_API_POSTFIX +
          url,
      headers: {
        Authorization:
          userToken && !external ? `Bearer ${userToken}` : undefined,
        ...axiosConfig.headers,
      },
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const errorResponse = error.response;
      throw errorResponse ? errorResponse.data : error.message;
    } else {
      throw error;
    }
  }
};
