import { useState } from "react";
import { cn } from "@/utils/utils";
import toast from "react-hot-toast";
import { AnimatePresence, motion } from "framer-motion";

import CopyIcon from "@/assets/icons/copy.svg?react";
import CheckIcon from "@/assets/icons/check.svg?react";

interface CopyProps {
  text: string;
  className?: string;
  hideTooltip?: boolean;
  children?: React.ReactNode;
  customView?: boolean;
}

const Copy = ({
  text,
  className,
  hideTooltip = false,
  children,
  customView,
}: CopyProps) => {
  const [success, showSuccess] = useState(false);

  const handleCopy = () => {
    try {
      navigator.clipboard.writeText(text);
      toast("Copied to your clipboard!", {
        icon: <CopyIcon className="text-tertiary size-4" />,
        duration: 3000,
      });
      showSuccess(true);
      setTimeout(() => {
        showSuccess(false);
      }, 3000);
    } catch (error) {
      toast.error(
        <p>
          Your browser cannot access the clipboard, please copy this text
          manually: <strong>{text}</strong>
        </p>
      );
    }
  };

  return (
    <button
      className={cn(
        "text-sm flex items-center gap-2 text-tertiary",
        className,
        {
          "has-tooltip": !hideTooltip && !children,
          "hover:underline": children,
        }
      )}
      onClick={() => handleCopy()}
      data-tooltip-text="Copy"
      disabled={success}
      aria-label="Copy"
      title="Copy"
    >
      {children}
      {!customView && (
        <AnimatePresence mode="wait">
          {success && (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ ease: "easeInOut" }}
            >
              <CheckIcon className="size-4 text-accent-blue dark:text-accent-main" />
            </motion.span>
          )}
          {!success && (
            <span>
              <CopyIcon className="text-tertiary size-4" />
            </span>
          )}
        </AnimatePresence>
      )}
    </button>
  );
};

export default Copy;
