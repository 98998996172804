import { sendRequest } from "@/utils/sendRequest";

export async function fetchCompanyInfo({ request }) {
  const code = new URL(request.url).searchParams.get("code");
  if (!code) return null;

  try {
    const res = sendRequest({
      url: `CompanyInvitation/GetInvitationByCode?code=${code}`,
      method: "GET",
    });
    return {
      companyData: res
        .then((response) => response.data)
        .catch((error) => {
          return null;
        }),
    };
  } catch (error) {
    return null;
  }
}
