import { MenuItem } from "@headlessui/react";
import { useFetchCategories } from "@/features/Categories/hooks/useFetchCategories";
import { Category } from "@/shared/types/categories";
import { cn } from "@/utils/utils";
import { categoriesIcons } from "@/features/Categories/categories";
import clsx from "clsx";
import Dropdown from "@/components/Dropdown";

// Icons
import CheckIcon from "@/assets/icons/check.svg?react";

interface ContentFilterProps {
  buttonClassName?: string;
  chevronClassName?: string;
  selectedOption?: Category;
  setSelectedOption: (category: Category) => void;
}

const ContentFilter = ({
  buttonClassName,
  chevronClassName,
  selectedOption,
  setSelectedOption,
}: ContentFilterProps) => {
  const { categories } = useFetchCategories();

  const options = [
    {
      id: "all",
      name: "All",
    } as unknown as Category,
    ...(categories ?? []),
  ];

  return (
    <Dropdown
      buttonText={selectedOption?.name ?? "All"}
      buttonClassName={cn("gap-[14px]", buttonClassName)}
      className="min-w-[280px] text-sm [--anchor-gap:12px] md:[--anchor-gap:24px] [--anchor-offset:30px] space-y-0 py-4 z-40"
      chevronClassName={chevronClassName}
      buttonTextClassName="max-md:hidden"
    >
      {options?.map((category, index) => {
        const CategoryIcon = categoriesIcons[category.id];
        return (
          <MenuItem key={category.id}>
            <button
              type="button"
              className={clsx(
                "w-full flex items-center gap-3 cursor-pointer py-[10px] hover:text-accent-blue dark:hover:text-accent-main data-[focus]:text-accent-blue dark:data-[focus]:text-accent-main",
                {
                  "border-b border-[#EEEEEE] dark:border-[#969696]":
                    index === 0,
                }
              )}
              onClick={() => setSelectedOption(category)}
            >
              <CategoryIcon className="text-[#969696] size-4" />
              <span
                className={clsx({
                  "text-accent-blue dark:text-accent-main":
                    selectedOption?.id === category.id,
                })}
              >
                {category.name}
              </span>
              {selectedOption?.id === category.id && (
                <CheckIcon className="text-accent-blue dark:text-accent-main ms-auto" />
              )}
            </button>
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};

export default ContentFilter;
