import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  logout,
  updateLocalPfp,
  userInCompanySelector,
  userInfoSelector,
  userRolesSelector,
} from "@/redux/App";
import { resetCart } from "@/redux/Cart";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { useRequest } from "@/hooks/useRequest";
import toast from "react-hot-toast";
import { generatePfpFileName } from "@/features/PfpUploader/utils/helpers";
import { Link, useNavigate } from "react-router";
import { AnimatePresence, motion } from "framer-motion";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { cn } from "@/utils/utils";
import Button from "../../Button";
import LogoutModal from "./LogoutModal";
import Divider from "@/components/Divider";
import PfpUpload from "./PfpUpload";
import DefaultAvatar from "@/components/Avatar/DefaultAvatar";

import CreatorBadgeIcon from "@/assets/icons/creator_badge.svg?react";
import LinkIcon from "@/assets/icons/external_link.svg?react";

interface UserDropdownProps {
  offset?: boolean;
}

const UserDropdown = ({ offset }: UserDropdownProps) => {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { sendRequest, loading } = useRequest();
  const [logoutModal, showLogoutModal] = useState(false);
  const userInfo = useAppSelector((state) => userInfoSelector(state));
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const isCompanyMember =
    useAppSelector(userRolesSelector)?.includes("CompanyMember");
  const isInCompany = useAppSelector(userInCompanySelector);
  const navigate = useNavigate();

  const handleSignOut = () => {
    dispatch(logout());
    dispatch(resetCart());
    showLogoutModal(false);
    navigate("/");
  };

  const handleLogoutClick = () => {
    showLogoutModal(true);
  };

  const handlePfpUpload = async (image: File) => {
    const formData = new FormData();
    formData.append("file", image, generatePfpFileName());

    try {
      await sendRequest({
        method: "PUT",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        url: "Users",
        data: formData,
      });
      // Update profile picture locally. On reload the actual pfp will be fetched
      // from the UserInfo API
      dispatch(updateLocalPfp(URL.createObjectURL(image)));
      toast.success("Your profile picture has been updated!");
      queryClient.invalidateQueries({
        queryKey: ["userInfo"],
      });
    } catch (error) {
      toast.error("Sorry, your profile picture could not be updated.");
    }
  };

  return (
    <>
      <LogoutModal
        open={logoutModal}
        onClose={() => showLogoutModal(false)}
        onLogout={handleSignOut}
      />
      <Popover className="relative flex items-center justify-center">
        {({ open }) => (
          <>
            <PopoverButton>
              <DefaultAvatar
                src={userInfo.profilePicture}
                alt={userInfo.fullName}
                className="size-[36px] border-none"
              />
            </PopoverButton>
            <AnimatePresence>
              {open && (
                <PopoverPanel
                  static
                  as={motion.div}
                  initial={{ opacity: 0, scale: 0.95 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.95 }}
                  anchor="bottom"
                  className={cn(
                    "flex flex-col z-50 bg-white dark:bg-tertiary-950 rounded-xl shadow-xl origin-top overflow-clip w-[278px] relative scrollbar-none [--anchor-gap:16px] ",
                    {
                      "!-translate-x-[10px]": offset,
                    }
                  )}
                >
                  <div
                    className="relative w-full min-h-[90px] bg-cover bg-center"
                    style={{
                      backgroundImage: "url('/images/profile/cover.jpg')",
                    }}
                  >
                    <div className="absolute -bottom-[54px] left-1/2 -translate-x-1/2 size-[100px]">
                      <DefaultAvatar
                        src={userInfo.profilePicture}
                        alt={userInfo.fullName}
                        className="size-[72px]"
                      />
                      <PfpUpload
                        onUpload={handlePfpUpload}
                        loading={loading}
                        containerClassName="absolute bottom-[23px] end-4"
                      />
                    </div>
                  </div>

                  <div className="px-4 pt-[48px] pb-6 space-y-5">
                    <h4 className="flex items-center justify-center gap-[12px] max-sm:text-[24px]">
                      <span className="truncate">{userInfo.fullName}</span>
                      <CreatorBadgeIcon
                        className="fill-accent-blue dark:fill-accent-main text-white dark:text-black shrink-0"
                        width={22}
                        height={22}
                      />
                    </h4>

                    <div className="space-y-6">
                      {!isCreator && !isInCompany && (
                        <Button
                          href="/become-creator"
                          variant="blue"
                          className="!mb-9 mx-4"
                        >
                          Become a creator
                        </Button>
                      )}
                      <Divider gradient={true} />
                      <ul className="space-y-4 px-4">
                        <li>
                          <Link
                            to={`/creator-dashboard${
                              !isCreator ? "/purchases" : ""
                            }`}
                            className="unstyled-link text-sm font-medium hover:underline"
                          >
                            My Profile
                          </Link>
                        </li>
                        {(isCreator || isCompanyMember) && (
                          <li>
                            <Link
                              to="/upload"
                              className="unstyled-link text-sm font-medium hover:underline"
                            >
                              Upload content
                            </Link>
                          </li>
                        )}
                        <li>
                          <Link
                            to="/invite-friends"
                            className="unstyled-link text-sm font-medium hover:underline"
                          >
                            Invite Friends
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/settings"
                            className="unstyled-link text-sm font-medium hover:underline"
                          >
                            Settings
                          </Link>
                        </li>
                      </ul>
                      <Divider gradient={true} />
                      <ul className="space-y-4 px-4">
                        <li>
                          <Link
                            to="/faq"
                            target="_blank"
                            className="flex justify-between items-center unstyled-link text-sm font-medium hover:underline"
                          >
                            Help Center <LinkIcon className="text-tertiary" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/terms-of-use"
                            target="_blank"
                            className="flex justify-between items-center unstyled-link text-sm font-medium hover:underline"
                          >
                            Terms of Use <LinkIcon className="text-tertiary" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/content-guidelines"
                            target="_blank"
                            className="flex justify-between items-center unstyled-link text-sm font-medium hover:underline"
                          >
                            Content Guidelines{" "}
                            <LinkIcon className="text-tertiary" />
                          </Link>
                        </li>
                      </ul>
                      <Divider gradient={true} />
                      <div className="px-4">
                        <Button
                          variant="link-unstyled"
                          onClick={handleLogoutClick}
                          className="text-sm"
                        >
                          Sign Out
                        </Button>
                      </div>
                    </div>
                  </div>
                </PopoverPanel>
              )}
            </AnimatePresence>
          </>
        )}
      </Popover>
    </>
  );
};

export default UserDropdown;
