import ShinyIcon from "@/assets/icons/misc/shiny.svg?react";
import { cn } from "@/utils/utils";

interface NewBadgeProps {
  className?: string;
  iconClassName?: string;
}

const NewBadge = ({ className, iconClassName }: NewBadgeProps) => {
  return (
    <div
      className={cn(
        "text-[11.97px] uppercase flex items-center gap-[6.29px] text-white dark:text-[#38313D] w-[56px] px-[6.04px] py-[2.33px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#7FB6F5] to-[#418FE8] dark:from-[#FAFFE7] dark:via-[#C3F500] dark:to-[#C3F500] z-0 relative after:absolute after:inset-0 after:size-full after:bg-[url('@/assets/images/noise.png')] after:-z-10 shadow-[0px_0px_113px_0px_rgba(153,_23,_255,_1)] rounded-full",
        className
      )}
    >
      New <ShinyIcon className={cn("size-[10.64px]", iconClassName)} />
    </div>
  );
};

export default NewBadge;
