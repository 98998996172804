import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector, userRolesSelector } from "@/redux/App";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import Button from "@/components/Button";
import ThemeToggler from "./ThemeToggler";
import MobileMenu from "./MobileMenu";
import Search from "@/features/Search";
import UserDropdown from "./UserDropdown";
import Cart from "./Cart";
import Divider from "../Divider";
import CurrencyPicker from "./CurrencyPicker";
import UploadButton from "../UploadButton";

interface NavProps {
  searchBar?: boolean;
  invertedColors?: boolean;
  userPfPOnly?: boolean;
  invertLogo?: boolean;
  invertLoginColor?: boolean;
  hideUploadButton?: boolean;
  hideAuthButtons?: boolean;
}

const Nav = ({
  searchBar = true,
  invertedColors = false,
  userPfPOnly = false,
  invertLogo = false,
  invertLoginColor = false,
  hideUploadButton = false,
  hideAuthButtons,
}: NavProps) => {
  const { t } = useTranslation();
  const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));
  const isCreator = useAppSelector(userRolesSelector)?.includes("Creator");
  const isCompanyMember =
    useAppSelector(userRolesSelector)?.includes("CompanyMember");

  return (
    <>
      {searchBar ? <Search hideFilterOnMobile={true} inHeader /> : <div></div>}
      {!userPfPOnly && (
        <MobileMenu
          invertedColors={invertedColors ? (invertLogo ? true : false) : false}
        />
      )}
      <div
        className={clsx("shrink-0 items-center gap-6 justify-end", {
          "hidden md:flex": !userPfPOnly,
          flex: userPfPOnly,
        })}
      >
        {isLoggedIn ? (
          <div className="flex items-center gap-6">
            {!userPfPOnly &&
              (isCreator || isCompanyMember) &&
              !hideUploadButton && <UploadButton muted />}
            <Cart />
            {!hideAuthButtons && <UserDropdown offset={userPfPOnly} />}
          </div>
        ) : (
          !hideAuthButtons && (
            <div className="flex items-center gap-2">
              <Button
                href="/sign-in"
                variant={
                  invertedColors ? "transparent-inverted" : "transparent"
                }
                className={
                  invertedColors
                    ? `${
                        invertLoginColor
                          ? "!text-white hover:!text-black"
                          : "!text-black"
                      } dark:!text-white dark:hover:!text-black`
                    : ""
                }
              >
                {t("sign_in")}
              </Button>
              <Button href="/sign-up" variant="default-dimmed">
                {t("sign_up")}
              </Button>
            </div>
          )
        )}
        {!userPfPOnly && (
          <>
            {hideAuthButtons && (
              <Divider vertical gradient className="h-[42px]" />
            )}
            <div className="flex items-center gap-4">
              <ThemeToggler />
              {isLoggedIn && <CurrencyPicker />}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Nav;
