import { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import { cn } from "@/utils/utils";

const HeroLayout = () => {
  const heroRef = useRef();
  const sentinelRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsSticky(!entry.isIntersecting);
      },
      {
        threshold: 0,
      }
    );

    if (sentinelRef.current) {
      observer.observe(sentinelRef.current);
    }

    const ref = sentinelRef;
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [pathname]);

  return (
    <>
      <div ref={sentinelRef} className="h-0"></div>
      <Header
        className={cn(
          "fixed top-0 left-0 z-40 dark:shadow-none h-[80px] bg-transparent transition-all duration-200",
          {
            "backdrop-blur-[11px]": isSticky,
          }
        )}
        searchBar={false}
        invertedColors={!isSticky}
        invertLoginColor={!isSticky}
        invertLogo={!isSticky}
      />
      <Outlet context={[heroRef]} />
      <Footer />
    </>
  );
};

export default HeroLayout;
