import { useEffect } from "react";
import { useRequest } from "@/hooks/useRequest";
import Modal from "@/components/Modal";
import IconWShadow from "@/components/IconWShadow";
import Button from "@/components/Button";
import confetti from "canvas-confetti";

import CupIcon from "@/assets/icons/misc/cup.svg?react";
import { randomNum } from "@/utils/helpers";

interface ProgressModalProps {
  show: boolean;
  onClose: () => void;
  progress: number;
}

const ProgressModal = ({ progress, show, onClose }: ProgressModalProps) => {
  const { sendRequest, loading } = useRequest();

  const handleDone = async () => {
    try {
      await sendRequest({
        method: "PUT",
        url: "Users/update-popup-flags",
        data: {
          hasSeenUpload100Popup: progress >= 100,
          hasSeenUpload500Popup: progress >= 500,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      onClose();
    }
  };

  const scalar = 3;
  const shape1 = confetti.shapeFromPath({
    path: "M23.97 23.3784C23.64 23.3784 23.31 23.3384 22.97 23.2684C19.25 22.4484 15.61 17.0684 14.79 14.5384C13.94 11.9084 13.99 9.11844 14.88 6.75844C10.58 4.44844 5.59 3.66844 2.23 7.41844L0 5.41844C4.56 0.338436 11 1.23844 16.37 4.14844C19.05 0.778439 22.11 0.578436 24.23 1.02844C30.4 2.31844 36.26 10.7084 37.42 15.6884L34.5 16.3684C33.52 12.1784 28.41 4.95844 23.62 3.95844C21.84 3.58844 20.3 4.16844 18.94 5.74844C19.45 6.10844 19.94 6.47844 20.41 6.85844C23.56 9.40844 25.82 12.0884 27.13 14.8084C27.75 16.0984 29.4 19.5284 27.2 21.9084C26.3 22.8784 25.18 23.3784 23.97 23.3784ZM17.49 8.40844C17.03 9.99844 17.07 11.8484 17.64 13.6184C18.28 15.5884 21.38 19.8584 23.61 20.3484C24.16 20.4684 24.57 20.3384 24.99 19.8784C25.54 19.2884 25.35 18.0584 24.42 16.1284C23.3 13.8084 21.32 11.4784 18.52 9.20844C18.19 8.93844 17.84 8.67844 17.49 8.41844V8.40844Z",
  });
  const shape2 = confetti.shapeFromPath({
    path: "M5.31397 0.443235L0.679688 2.32031L6.53242 16.77L11.1667 14.8929L5.31397 0.443235Z",
  });
  const shape3 = confetti.shapeFromPath({
    path: "M4.10097 37.0797L1.25097 38.0297C-0.61903 32.4097 1.38097 25.7697 6.01097 22.0897C6.10097 22.0197 6.19097 21.9497 6.28097 21.8797C6.44097 21.7597 6.60097 21.6397 6.77097 21.5197C7.41097 21.0597 8.01097 20.6197 8.25097 20.1197C8.58097 19.4297 8.30097 18.5397 7.81097 17.1597C7.43097 16.0897 6.99097 14.8697 7.02097 13.5697C7.06097 11.5397 8.18097 9.93969 9.17097 8.51969C9.54097 7.98969 9.88097 7.49969 10.171 6.99969C10.671 6.10969 10.811 4.82969 10.481 4.07969C10.331 3.72969 10.141 3.64969 9.97097 3.60969L10.641 0.679688C11.801 0.949688 12.721 1.71969 13.231 2.85969C13.951 4.47969 13.771 6.73969 12.781 8.46969C12.431 9.08969 12.031 9.66969 11.641 10.2297C10.831 11.3997 10.061 12.4997 10.031 13.6297C10.011 14.3797 10.321 15.2397 10.651 16.1397C11.221 17.7197 11.861 19.5197 10.981 21.3797C10.421 22.5797 9.42097 23.2997 8.54097 23.9297C8.39097 24.0397 8.25097 24.1397 8.10097 24.2497C4.30097 27.1297 2.59097 32.5097 4.11097 37.0497L4.10097 37.0797Z",
  });
  const shape4 = confetti.shapeFromPath({
    path: "M29.2019 0.679688L32.0219 1.70969C30.0019 7.27969 24.3019 11.2397 18.3919 11.2397C18.2719 11.2397 18.1619 11.2397 18.0419 11.2397C17.8419 11.2397 17.6419 11.2297 17.4419 11.2197C16.6519 11.1797 15.9119 11.1497 15.4119 11.3997C14.7219 11.7397 14.3919 12.6097 13.9219 13.9897C13.5519 15.0697 13.1419 16.2897 12.3119 17.2897C11.0219 18.8497 9.14191 19.4097 7.49191 19.9097C6.88191 20.0897 6.29192 20.2697 5.76192 20.4797C4.81192 20.8597 3.91191 21.7797 3.70191 22.5697C3.60191 22.9397 3.70191 23.1197 3.81191 23.2497L1.47192 25.1297C0.721916 24.1997 0.491918 23.0197 0.801918 21.8097C1.25192 20.0897 2.79191 18.4397 4.64191 17.6897C5.30191 17.4197 5.97191 17.2197 6.63191 17.0197C7.99191 16.6097 9.28191 16.2197 10.0019 15.3597C10.4819 14.7797 10.7719 13.9197 11.0819 13.0097C11.6219 11.4197 12.2319 9.60969 14.0819 8.69969C15.2719 8.10969 16.5019 8.15969 17.5819 8.20969C17.7619 8.20969 17.9419 8.22969 18.1219 8.22969C22.8919 8.32969 27.5719 5.17969 29.2019 0.679688Z",
  });

  useEffect(() => {
    const defaults = {
      spread: randomNum(240, 360),
      ticks: randomNum(60, 140),
      gravity: 0.2,
      decay: 0.96,
      startVelocity: randomNum(10, 20),
      shapes: [shape1, shape2, shape3, shape4],
      scalar,
      colors: ["FCC145", "EB2532", "FF790A", "78A9EF"],
    };

    const shoot = () => {
      confetti({
        ...defaults,
        particleCount: randomNum(40, 60),
      });

      confetti({
        ...defaults,
        particleCount: randomNum(5, 15),
      });

      confetti({
        ...defaults,
        particleCount: randomNum(15, 30),
        scalar: 0.6,
        shapes: ["circle"],
      });
    };

    if (show) {
      shoot();
      const interval = setInterval(() => {
        shoot();
      }, 2000);
      // Cleanup
      return () => clearInterval(interval);
    }
  }, [shape1, shape2, shape3, shape4, show]);

  return (
    <Modal
      open={show}
      onClose={() => onClose()}
      className="!max-w-[320px] sm:!max-w-[646px] !h-[394px] sm:!h-[436px] !pt-16 !pb-4 sm:!px-[50px]"
    >
      <IconWShadow color="blue">
        <CupIcon className="text-white" />
      </IconWShadow>
      <h2 className="font-semibold sm:font-bold text-lg sm:text-[25px] text-center mt-[20px] sm:mt-[26px]">
        {progress} uploads achievement
      </h2>
      <p className="max-sm:leading-[1.525] max-sm:text-xs text-tertiary mt-[16px] text-center">
        Congratulations! you’ve reached <strong>{progress} content</strong>{" "}
        uploaded! You’re now part of the A tier creatives on Mohtawa.
        <br />
        <br />
        Keep uploading to reach new levels!
      </p>
      <Button
        className="w-full sm:w-[260px] mx-auto mt-[20px] sm:mt-[28px]"
        loading={loading}
        onClick={() => handleDone()}
      >
        Done
      </Button>
    </Modal>
  );
};

export default ProgressModal;
