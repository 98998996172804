import { forwardRef } from "react";
import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import Logo from "@/components/Logo";
import Nav from "./Nav";
import { cn } from "@/utils/utils";

interface HeaderProps {
  className?: string;
  logoOnly?: boolean;
  searchBar?: boolean;
  invertedColors?: boolean;
  userPfPOnly?: boolean;
  invertLogo?: boolean;
  invertLoginColor?: boolean;
  hideUploadButton?: boolean;
  hideAuthButtons?: boolean;
  fixed?: boolean;
  logoClassName?: string;
}

const Header = forwardRef(
  (
    {
      className = "",
      logoOnly = false,
      searchBar = true,
      invertedColors = false,
      invertLogo = false,
      userPfPOnly = false,
      invertLoginColor = false,
      hideUploadButton = false,
      hideAuthButtons,
      fixed,
      logoClassName,
    }: HeaderProps,
    ref: any
  ) => {
    const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));

    return (
      <header
        className={cn(
          "w-full container max-w-full flex justify-between gap-4 xl:gap-10 py-4 items-center h-[5rem]",
          {
            "fixed bg-white/5 backdrop-blur-sm dark:bg-[#1A1919]/5 z-40 origin-top !py-3 top-0 left-0 shadow-lg shadow-tertiary-gray/5":
              fixed,
          },
          className
        )}
        ref={ref}
        dir="ltr"
      >
        <Logo
          className={cn(
            "shrink-0 size-[33px] lg:size-[43px]",
            {
              "fill-white": invertLogo && invertedColors,
            },
            logoClassName
          )}
        />
        {(!logoOnly || isLoggedIn) && (
          <Nav
            searchBar={searchBar}
            invertedColors={invertedColors}
            userPfPOnly={userPfPOnly}
            invertLogo={invertLogo}
            invertLoginColor={invertLoginColor}
            hideUploadButton={hideUploadButton}
            hideAuthButtons={hideAuthButtons}
          />
        )}
      </header>
    );
  }
);

export default Header;
