import { useRef } from "react";
import { isDarkModeSelector } from "@/redux/App";
import { useAppSelector } from "@/redux/hooks";
import { AudioVisualizer } from "react-audio-visualize";
import ReactSlider from "react-slider";
import { cn } from "@/utils/utils";

interface WavePlayerProps {
  audioBlob: Blob;
  currentTime?: number;
  duration?: number;
  handleChangeTime: (percentage: number) => void;
  desktopOnly?: boolean;
  className?: string;
  wave?: {
    width?: number;
    height?: number;
  };
  thumbClassName?: string;
}

const WavePlayer = ({
  audioBlob,
  duration,
  currentTime,
  handleChangeTime,
  desktopOnly,
  className,
  wave,
  thumbClassName,
}: WavePlayerProps) => {
  const isDarkMode = useAppSelector(isDarkModeSelector);
  const visualizerRef = useRef<HTMLCanvasElement>(null);

  if (!audioBlob) return <></>;

  return (
    <div
      className={cn(
        "relative flex-1 min-w-[auto]",
        {
          "max-lg:hidden": desktopOnly,
        },
        className
      )}
    >
      <AudioVisualizer
        key={`audioVisualizerTheme_${isDarkMode}`}
        ref={visualizerRef}
        blob={audioBlob}
        width={wave?.width ?? 356}
        height={wave?.height ?? 67}
        barWidth={2.5}
        gap={2}
        barColor={"#999999"}
        currentTime={currentTime}
        barPlayedColor={isDarkMode ? "#c3f500" : "#418FE8"}
        style={{ width: "100%", height: "100%" }}
      />
      <ReactSlider
        value={duration ? ((currentTime ?? 0) / duration) * 100 : 0}
        step={0.01}
        onChange={(percentage) => handleChangeTime(percentage)}
        className="!absolute size-full flex items-center top-1/2 -translate-y-1/2 inset-0"
        renderTrack={(props, state) => (
          <div key={props.key} style={props.style}>
            {state.index !== 0 && (
              <div className="h-full rounded bg-accent-blue/40 dark:bg-accent-main/40" />
            )}
          </div>
        )}
        thumbClassName={cn(
          "rounded-full bg-accent-blue dark:bg-accent-main outline-none size-[5px]",
          thumbClassName
        )}
      />
    </div>
  );
};

export default WavePlayer;
