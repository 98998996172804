import { useEffect, useState } from "react";
import { useRequest } from "@/hooks/useRequest";
import { bulkUploadPopupSelector, showBulkUploadPopup } from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { SubmitHandler, useForm } from "react-hook-form";
import { validationRegex } from "@/utils/validation";
import { Link } from "react-router";
import { cn } from "@/utils/utils";
import toast from "react-hot-toast";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import Input from "@/components/Input";
import SuccessModal from "./SuccessModal";

import ShinyIcon from "@/assets/icons/misc/shiny.svg?react";
import GDriveIcon from "@/assets/icons/brands/gdrive.svg?react";
import OneDriveIcon from "@/assets/icons/brands/onedrive.svg?react";
import DropboxIcon from "@/assets/icons/brands/dropbox.svg?react";

type FormFields = {
  url: string;
};

const BulkUploadPopup = () => {
  const [success, showSuccess] = useState(false);
  const [chosenService, setChosenService] = useState<
    "gdrive" | "onedrive" | "dropbox"
  >();
  const { sendRequest, loading } = useRequest();
  const bulkUploadOpen = useAppSelector(bulkUploadPopupSelector);
  const dispatch = useAppDispatch();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
  } = useForm<FormFields>();
  const value = watch("url");

  const handleClose = () => {
    dispatch(showBulkUploadPopup(false));
    showSuccess(false);
    setValue("url", "");
  };

  const handleFormSubmit: SubmitHandler<FormFields> = async (data) => {
    const url = data.url;
    try {
      await sendRequest({
        method: "POST",
        url: "BulkUpload",
        data: {
          link: url,
          notes: "",
        },
      });
      showSuccess(true);
    } catch (error) {
      console.error(error);
      toast.error("An error occurred. Please try again later.");
    }
  };

  useEffect(() => {
    if (/^(https?:\/\/)?(www\.)?drive\.google\.com\//.test(value)) {
      setChosenService("gdrive");
    } else if (/^(https?:\/\/)?(www\.)?onedrive\.live\.com\//.test(value)) {
      setChosenService("onedrive");
    } else if (/^(https?:\/\/)?(www\.)?dropbox\.com\//.test(value)) {
      setChosenService("dropbox");
    } else {
      setChosenService(undefined);
    }
  }, [value]);

  return (
    <>
      {success && <SuccessModal open={success} onClose={() => handleClose()} />}
      <Modal
        open={bulkUploadOpen}
        onClose={() => handleClose()}
        className="!max-w-[745px] !py-[40px] !px-[20px] relative overflow-visible dark:bg-tertiary-dark-gray"
        closeIconSize={29}
      >
        <div
          className="text-[19px] uppercase flex items-center gap-[10px] text-white dark:text-[#38313D] rounded-sm w-[85px] px-[9px] py-[3.5px] start-[40px] absolute -top-[7px] bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-[#7FB6F5] to-[#418FE8] dark:from-[#FAFFE7] dark:via-[#C3F500] dark:to-[#C3F500] z-0
                before:absolute before:size-0 before:border-l-transparent before:border-b-[#5799ED] dark:before:border-b-[#D8F852] before:border-r-transparent before:-z-10 before:top-0 before:-left-[6px]
                before:border-l-[7.45px]
                before:border-b-[7.45px]
                before:border-r-[4px]
            after:absolute after:inset-0 after:size-full after:bg-[url('@/assets/images/noise.png')] after:-z-10
            shadow-[0px_0px_180px_0px_rgba(153,_23,_255,_1)]"
        >
          New <ShinyIcon className="size-[16.91px]" />
        </div>
        <h3 className="md:text-[24px] font-medium px-[20px]">
          Add link to bulk upload
        </h3>
        <p className="max-md:text-sm text-tertiary mt-[8px] mb-[30px] px-[20px]">
          Upload your content in bulk with Google Drive, visit our{" "}
          <Link to="/bulk-upload-guidelines" target="_blank">
            guide page
          </Link>{" "}
          for more info
        </p>
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <div className="flex">
            <Input
              containerClassName="ps-[20px] flex-1 min-w-[auto]"
              className="rounded-s-lg rounded-e-lg sm:rounded-e-none text-tertiary text-base dark:bg-[#232323]"
              type="url"
              placeholder="https://drive.google.com/..."
              error={errors.url}
              {...register("url", {
                required: true,
                pattern: {
                  value: validationRegex.url,
                  message: "Please enter a valid URL",
                },
              })}
            />
            <div className="hidden sm:flex items-center gap-[10px] rounded-e-lg bg-tertiary-50 dark:bg-[#232323] px-[20px]">
              <DropboxIcon
                className={cn("transition-opacity duration-300 opacity-30", {
                  "opacity-100": chosenService === "dropbox" || !chosenService,
                })}
              />
              <GDriveIcon
                className={cn("transition-opacity duration-300 opacity-30", {
                  "opacity-100": chosenService === "gdrive" || !chosenService,
                })}
              />
              <OneDriveIcon
                className={cn("transition-opacity duration-300 opacity-30", {
                  "opacity-100": chosenService === "onedrive" || !chosenService,
                })}
              />
            </div>
          </div>
          <div className="mt-[50px] flex max-sm:flex-col items-center justify-end gap-1 sm:gap-[38.5px]">
            <Button
              type="button"
              variant="transparent"
              className="!w-[101px] max-sm:order-2"
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="blue"
              loading={loading}
              className="max-sm:w-full"
            >
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default BulkUploadPopup;
