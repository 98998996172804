import { cn } from "@/utils/utils";
import { Link, useLocation } from "react-router";
import { AnimatePresence, motion } from "framer-motion";

interface TabButtonProps {
  href: string;
  children: React.ReactNode;
}

const TabButton = ({ href, children }: TabButtonProps) => {
  const { pathname } = useLocation();
  const isActive = pathname === href;

  return (
    <li className="relative">
      <AnimatePresence>
        <Link
          to={href}
          className={cn(
            "unstyled-link text-sm flex pb-[17px] transition-colors duration-200",
            {
              "text-accent-blue dark:text-accent-main": isActive,
            }
          )}
        >
          {children}
          {isActive && (
            <motion.span
              initial={{ width: 0 }}
              animate={{ width: "100%" }}
              exit={{ width: 0 }}
              className="w-full h-[3px] bg-accent-blue dark:bg-accent-main absolute -bottom-[1px] rounded-t-full"
            />
          )}
        </Link>
      </AnimatePresence>
    </li>
  );
};

export default TabButton;
