import Popover from "@/components/Popover";
import Copy from "@/components/Copy";

import LinkIcon from "@/assets/icons/link.svg?react";
import OpenIcon from "@/assets/icons/more.svg?react";
import { FaFacebookF, FaPinterestP, FaXTwitter } from "react-icons/fa6";

interface ShareProps {
  productId?: string;
  title?: string;
}

const Share = ({ productId, title }: ShareProps) => {
  const contentUrl = `${import.meta.env.VITE_APP_URL}/product/${productId}`;

  return (
    <Popover
      button={<OpenIcon className="text-tertiary" />}
      anchor="left start"
      className="space-y-4 w-[211px]"
    >
      <Copy
        text={contentUrl}
        hideTooltip
        customView
        className="flex items-center gap-2 unstyled-link hover:underline text-sm"
      >
        <LinkIcon className="text-tertiary size-[16px]" />
        <span className="text-black dark:text-white">Copy link</span>
      </Copy>
      <a
        href={`https://x.com/share?text=${title}&url=${contentUrl}`}
        rel="noreferrer"
        target="_blank"
        className="flex items-center gap-2 unstyled-link hover:underline text-sm"
      >
        <FaXTwitter className="text-black dark:text-white size-[16px]" />
        <span>X (Twitter)</span>
      </a>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
          contentUrl
        )}&t=${title}`}
        rel="noreferrer"
        target="_blank"
        className="flex items-center gap-2 unstyled-link hover:underline text-sm"
      >
        <FaFacebookF className="text-[#0865fe] size-[16px]" />
        <span>Facebook</span>
      </a>
      <a
        href={`https://pinterest.com/pin/create/link/?url=${encodeURI(
          contentUrl
        )}`}
        rel="noreferrer"
        target="_blank"
        className="flex items-center gap-2 unstyled-link hover:underline text-sm"
      >
        <FaPinterestP className="text-[#e7052a] size-[16px]" />
        <span>Pinterest</span>
      </a>
    </Popover>
  );
};

export default Share;
