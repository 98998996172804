import { useEffect, useRef, useState } from "react";
import { MediaData } from "@/shared/types/video";
import { useHotkeys } from "react-hotkeys-hook";

export const useAudioPlayer = (autoPlay?: boolean, prefetchAudio?: boolean) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioBlob, setAudioBlob] = useState<Blob>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioData, setAudioData] = useState<MediaData>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleAudioPlay = () => {
    const audio = audioRef.current;
    if (!audio) return;
    if (audio.paused) {
      setIsPlaying(true);
      audio.play();
    } else {
      setIsPlaying(false);
      audio.pause();
    }
  };

  const handleBuffering = () => {
    setAudioData((prev) => ({
      ...prev,
      buffering: true,
    }));
  };

  const handleStopBuffering = () => {
    setAudioData((prev) => ({
      ...prev,
      buffering: false,
    }));
  };

  const handleSeek = (time: number) => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.currentTime = time;
    setAudioData((prev) => ({
      ...prev,
      currentTime: time,
    }));
  };

  const handleMute = () => {
    const audio = audioRef.current;
    if (!audio) return;
    audio.muted = !audio.muted;
    setAudioData((prev) => ({
      ...prev,
      muted: audio.muted,
    }));
  };

  const handleChangeVolume = (volume: number) => {
    const audio = audioRef.current;
    if (!audio) return;
    if (volume > 1) volume = 1;
    if (volume < 0) volume = 0;
    audio.volume = volume || 0;
    setAudioData((prev) => ({
      ...prev,
      volume,
    }));
  };

  const handleChangeTime = (percent: number) => {
    const time = (percent / 100) * (audioData?.duration ?? 0);
    const audio = audioRef.current;
    if (!audio) return;
    audio.currentTime = time;
    setAudioData((prev) => ({
      ...prev,
      currentTime: time,
    }));
  };

  // Hotkeys
  useHotkeys("space", (e) => {
    e.preventDefault();
    handleAudioPlay();
  });
  useHotkeys("up", (e) => {
    e.preventDefault();
    handleChangeVolume((audioData?.volume ?? 0) + 0.1);
  });
  useHotkeys("down", (e) => {
    e.preventDefault();
    handleChangeVolume((audioData?.volume ?? 0) - 0.1);
  });
  useHotkeys("m", (e) => {
    e.preventDefault();
    handleMute();
  });
  useHotkeys("right", (e) => {
    e.preventDefault();
    handleSeek((audioData?.currentTime ?? 0) + 1);
  });
  useHotkeys("shift+right", (e) => {
    e.preventDefault();
    handleSeek((audioData?.currentTime ?? 0) + 5);
  });
  useHotkeys("left", (e) => {
    e.preventDefault();
    handleSeek((audioData?.currentTime ?? 0) - 1);
  });
  useHotkeys("shift+left", (e) => {
    e.preventDefault();
    handleSeek((audioData?.currentTime ?? 0) - 5);
  });

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;
    if (autoPlay) {
      audio.play();
    }
  }, [autoPlay]);

  useEffect(() => {
    const audio = audioRef.current;
    if (!audio) return;

    const handleLoadedMetadata = async () => {
      setAudioData({
        duration: audio.duration,
        currentTime: audio.currentTime,
        volume: audio.volume,
        muted: audio.muted,
      });
      if (!prefetchAudio) return;
      await fetch(audio.src)
        .then((res) => res.blob())
        .then((blob) => setAudioBlob(blob))
        .catch((err) => {
          console.error(err);
          setAudioBlob(undefined);
        });
    };
    const handleLoaded = () => {
      setError(false);
      setLoading(false);
    };
    const handleLoadError = () => {
      setError(true);
      setAudioBlob(undefined);
    };
    const handlePlay = () => {
      setIsPlaying(true);
    };
    const handlePause = () => {
      setIsPlaying(false);
    };
    const handleProgress = () => {
      setAudioData((prev) => ({
        ...prev,
        progress: audio.buffered.length
          ? audio.buffered.end(audio.buffered.length - 1)
          : 0,
      }));
    };
    const handleTimeUpdate = () => {
      setAudioData((prev) => ({
        ...prev,
        currentTime: audio.currentTime,
      }));
    };
    const handleVolumeChange = () => {
      setAudioData((prev) => ({
        ...prev,
        volume: audio.volume,
        muted: audio.muted,
      }));
    };

    audio.addEventListener("loadedmetadata", handleLoadedMetadata);
    audio.addEventListener("loadeddata", handleLoaded);
    audio.addEventListener("error", handleLoadError);
    audio.addEventListener("play", handlePlay);
    audio.addEventListener("pause", handlePause);
    audio.addEventListener("progress", handleProgress);
    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("volumechange", handleVolumeChange);
    audio.addEventListener("waiting", handleBuffering);
    audio.addEventListener("playing", handleStopBuffering);

    return () => {
      audio.removeEventListener("loadedmetadata", handleLoadedMetadata);
      audio.removeEventListener("loadeddata", handleLoaded);
      audio.removeEventListener("error", handleLoadError);
      audio.removeEventListener("play", handlePlay);
      audio.removeEventListener("pause", handlePause);
      audio.removeEventListener("progress", handleProgress);
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("volumechange", handleVolumeChange);
      audio.removeEventListener("waiting", handleBuffering);
      audio.removeEventListener("playing", handleStopBuffering);
    };
  }, [prefetchAudio]);

  return {
    audioRef,
    audioData,
    loading,
    error,
    isPlaying,
    audioBlob,
    handleAudioPlay,
    handleMute,
    handleChangeVolume,
    handleChangeTime,
  };
};
