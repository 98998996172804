import { useOrder } from "@/pages/CreatorDashboard/Order/hooks/useOrder";
import { formatPrice } from "@/utils/helpers";
import { Outlet } from "react-router";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import Button from "@/components/Button";
import Divider from "@/components/Divider";
import PageLoader from "@/components/PageLoader";
import OrderTable from "@/pages/CreatorDashboard/Order/components/OrderTable";

const OrderLayout = () => {
  const { data, isError, isLoading, orderId } = useOrder();

  if (isLoading) return <PageLoader />;

  return (
    <>
      <Header className="mb-10 sm:mb-[60px] shadow-lg shadow-gray-50 dark:shadow-none" />
      <main className="container">
        <div className="space-y-[62px] sm:space-y-[88px]">
          <Outlet />
          <div className="space-y-2">
            <h4 className="max-sm:text-lg font-semibold ms-3">
              Purchase details
            </h4>
            {!isError && (
              <OrderTable
                products={data?.products ?? []}
                currency={data?.currency ?? "AED"}
                rate={data?.rate ?? 1}
                orderId={orderId}
              />
            )}
            {isError && (
              <p className="text-error-default dark:text-error-dark-default ms-3">
                The details about the order failed to load. Please reload this
                page or contact us if this issue persists.
              </p>
            )}
          </div>
        </div>
        <Divider gradient className="mt-[24px] mb-[15px] max-sm:hidden" />
        <div className="flex max-sm:flex-col justify-between items-center gap-10 max-sm:mt-[24px]">
          <div className="font-medium sm:ms-8 max-sm:flex justify-between max-sm:w-full">
            <span>Total:</span>{" "}
            <span>
              {data?.currency || "AED"} {formatPrice(data?.totalPrice ?? 0)}
            </span>
          </div>
          <div className="flex max-sm:w-full max-sm:flex-col items-center justify-end gap-2">
            <Button
              href="/creator-dashboard/purchases"
              variant="transparent"
              className="max-sm:order-2 max-sm:w-full !px-3"
            >
              Back
            </Button>
            <Button href="/" variant="blue" className="max-sm:w-full !px-5">
              Continue shopping
            </Button>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default OrderLayout;
