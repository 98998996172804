import { useRequest } from "@/hooks/useRequest";
import { Product } from "@/shared/types/product";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";

export const useOrder = () => {
  const { orderId } = useParams();
  const { sendRequest } = useRequest();
  const { data, isLoading, isError } = useQuery({
    queryKey: ["order", { orderId }],
    retry: false,
    queryFn: async () => {
      const res = await sendRequest({
        method: "GET",
        url: `Orders/GetOrderById:${orderId}`,
      });
      const data: {
        data: {
          totalAmount: number;
          addedDate: string | number;
          currency: string | null;
          rate: number;
          orderDetails: {
            $values: Product[];
          };
        };
      } = await res.data;
      return {
        totalPrice: data.data.totalAmount,
        addedDate: data.data.addedDate,
        products: data.data.orderDetails.$values,
        currency: data.data.currency,
        rate: data.data.rate,
      };
    },
  });

  return {
    data,
    isLoading,
    isError,
    orderId,
  };
};
