import clsx from "clsx";
import { Link, useLocation } from "react-router";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import ChevronDownIcon from "@/assets/icons/chevron_down.svg?react";
import CogIcon from "@/assets/icons/cog.svg?react";
import LinkIcon from "@/assets/icons/external_link.svg?react";
import Divider from "@/components/Divider";

interface MenuProps {
  showMenu: boolean;
  onToggle: () => void;
}

const Menu = ({ showMenu = false, onToggle }: MenuProps) => {
  const { pathname } = useLocation();

  return (
    <aside
      className={clsx(
        "bg-tertiary-50 dark:bg-tertiary-950 rounded-lg w-full max-md:min-h-[541px] md:max-w-[280px] md:max-h-[550px]",
        {
          "max-md:hidden": !showMenu,
        }
      )}
    >
      <Disclosure as="div" className="p-6" defaultOpen={true}>
        <DisclosureButton className="group flex w-full items-center gap-4 justify-center md:justify-between text-tertiary text-sm px-4 max-md:pe-14">
          <CogIcon />
          <span>Account Settings</span>
          <ChevronDownIcon className="group-data-[open]:rotate-180" />
        </DisclosureButton>
        <DisclosurePanel className="mt-2 text-sm/5 text-white/50">
          <ul className="space-y-5 py-3 ps-[60px]">
            <li>
              <Link
                to="/settings"
                className={clsx("text-sm unstyled-link", {
                  "text-black dark:text-white font-medium":
                    pathname === "/settings",
                  "text-tertiary": pathname !== "/settings",
                })}
                onClick={onToggle}
              >
                Personal Information
              </Link>
            </li>
            {/* <li>
              <Link
                to="/settings/payment-info"
                className={clsx("text-sm unstyled-link", {
                  "text-black dark:text-white font-medium":
                    pathname === "/settings/payment-info",
                  "text-tertiary": pathname !== "/settings/payment-info",
                })}
              >
                Payment Method
              </Link>
            </li> */}
          </ul>
        </DisclosurePanel>
      </Disclosure>
      <Divider className="w-[84%] mx-auto mb-5 md:hidden" />
      <ul className="space-y-4 px-14 mb-5 md:hidden">
        <li>
          <Link
            to="/faq"
            target="_blank"
            className="flex justify-between items-center unstyled-link text-sm font-medium text-tertiary"
          >
            Help Center <LinkIcon />
          </Link>
        </li>
        <li>
          <Link
            to="/terms-of-use"
            target="_blank"
            className="flex justify-between items-center unstyled-link text-sm font-medium text-tertiary"
          >
            Terms of Use <LinkIcon />
          </Link>
        </li>
      </ul>
    </aside>
  );
};

export default Menu;
