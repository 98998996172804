import { cn } from "@/utils/utils";
import { Marquee } from "@/components/MagicUI/Marquee";
import { SignupLayoutProps } from "..";
import CarouselPill from "./CarouselPill";

// Images
import signup_bg from "@/assets/images/auth/signup_bg.webp";
import become_creator_bg from "@/assets/images/become_creator_bg.webp";

const Side = ({ creatorPage = false }: SignupLayoutProps) => {
  return (
    <section
      className={cn(
        "bg-cover bg-center flex-grow flex flex-col gap-[18px] lg:gap-[40px] py-6 lg:justify-end text-white",
        {
          "lg:w-[49%] max-lg:h-[18.5rem] lg:min-h-screen lg:py-10 lg:gap-10":
            creatorPage,
          "lg:w-[46%] max-lg:h-[276px] lg:min-h-screen lg:pb-[77px] lg:gap-10":
            !creatorPage,
        }
      )}
      style={{
        backgroundImage: `url(${creatorPage ? become_creator_bg : signup_bg})`,
      }}
    >
      <h1
        className={cn("text-xl font-bold leading-[1.563rem] px-6 mt-auto", {
          "lg:text-[2.5rem] lg:leading-[2.938rem] lg:px-10": creatorPage,
          "text-[20px] leading-[25px] lg:text-[40px] lg:leading-[47px] lg:px-[52px]":
            !creatorPage,
        })}
      >
        {creatorPage ? (
          <>
            Share your content
            <br />
            with people, and <br className="max-sm:hidden" />
            gain{" "}
            <span className="bg-gradient-to-r from-[#418FE8] to-[#8A69CC] dark:from-accent-main dark:to-accent-main bg-clip-text text-transparent">
              recognition
            </span>{" "}
            in <br className="max-sm:hidden" />
            no time!
          </>
        ) : (
          <>
            Get unlimited
            <br /> cultural content. And
            <br /> join{" "}
            <span className="bg-gradient-to-r from-[#418FE8] to-[#8A69CC] dark:from-accent-main dark:to-accent-main bg-clip-text text-transparent">
              Mohtawa
            </span>{" "}
            now!
          </>
        )}
      </h1>
      <Marquee className="[--duration:40s] [--gap:0.1px]">
        <CarouselPill>Culture</CarouselPill>
        <CarouselPill>Content</CarouselPill>
        <CarouselPill>Community</CarouselPill>
        <CarouselPill>Technology</CarouselPill>
      </Marquee>
    </section>
  );
};

export default Side;
