import { twMerge } from "tailwind-merge";
import Button from "@/components/Button";
import PfpUploader from "@/features/PfpUploader";

import PenIcon from "@/assets/icons/creator_dashboard/pen.svg?react";

interface PfpUploadProps {
  containerClassName?: string;
  buttonClassName?: string;
  onUpload: (image: File) => void;
  loading?: boolean;
}

const PfpUpload = ({
  onUpload,
  loading = false,
  buttonClassName,
  containerClassName,
}: PfpUploadProps) => {
  return (
    <PfpUploader onChange={onUpload} className={containerClassName || ""}>
      {(image) => (
        <Button
          tiny={true}
          icon={PenIcon}
          className={twMerge("!size-[32px]", buttonClassName || "")}
          disabled={loading}
        ></Button>
      )}
    </PfpUploader>
  );
};

export default PfpUpload;
