import { cn } from "@/utils/utils";

import CreatorBadgeIcon from "@/assets/icons/creator_badge.svg?react";
import HelpTooltip from "@/components/HelpTooltip";

interface ProgressBarProps {
  progress: number;
}

const ProgressBar = ({ progress }: ProgressBarProps) => {
  const maxUploads = (progress ?? 0) <= 100 ? 100 : 500;

  return (
    <div className="space-y-2 mt-[13px]">
      <div className="relative">
        <div className="w-full h-[7px] bg-tertiary-50 dark:bg-tertiary-dark-gray lg:bg-white lg:dark:bg-[#232323] rounded-full overflow-hidden">
          <div
            className="h-[7px] bg-gradient-[120deg] dark:bg-gradient-[160deg] from-[#D370E4] via-[#6B56DA] to-[#4C9FC0] dark:from-black dark:via-[#627B00] dark:to-[#C3F500] rounded-full"
            style={{ width: `${((progress ?? 0) / maxUploads) * 100}%` }}
          ></div>
        </div>
        <div className="size-[39px] rounded-full bg-tertiary-50 dark:bg-tertiary-dark-gray lg:bg-white lg:dark:bg-[#232323] absolute right-0 top-1/2 -translate-y-1/2 flex items-center justify-center">
          <HelpTooltip
            container={
              <CreatorBadgeIcon
                className={cn("text-white size-[22px]", {
                  "fill-accent-blue dark:fill-accent-main dark:text-tertiary-dark-gray":
                    progress < 100,
                  "fill-accent-purple dark:fill-accent-green dark:fill-tertiary-dark-gray dark:text-accent-main":
                    progress >= 100,
                })}
              />
            }
            className="sm:w-[201px] !px-2 text-sm"
          >
            Upload {maxUploads} pieces of content and unlock 40% revenue share
            for life!
          </HelpTooltip>
        </div>
      </div>
      <p className="text-xs text-tertiary text-start">
        {progress}/{maxUploads} uploads
      </p>
    </div>
  );
};

export default ProgressBar;
