import { useAppSelector } from "@/redux/hooks";
import { isLoggedInSelector } from "@/redux/App";
import { useRequest } from "@/hooks/useRequest";
import { useQuery } from "@tanstack/react-query";
import Button from "@/components/Button";

// import { ReactComponent as TimesIcon } from "@/assets/icons/times.svg";

const RecentSearches = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { sendRequest } = useRequest();
  const fetchRecentSearches = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: "Search",
      });
      const data: {
        data: { $values: { userId: string; searchText: string }[] };
      } = await res.data;

      const uniqueSearches = new Set(
        data.data.$values.map((item) => item.searchText)
      );
      return Array.from(uniqueSearches);
    } catch (error) {
      console.error(error);
    }
  };

  const { data: searchTerms } = useQuery({
    queryKey: ["searchTerms"],
    queryFn: fetchRecentSearches,
    enabled: isLoggedIn,
    staleTime: 1000 * 60 * 60,
  });

  return (
    <div className="space-y-5">
      <div className="flex gap-3 items-center max-md:text-sm">
        <p>Recent Searches -</p>
        {/* <button className="text-tertiary">Clear</button> */}
      </div>
      {searchTerms && searchTerms.length > 0 ? (
        <div className="flex flex-wrap gap-4">
          {searchTerms?.map((term, index) => (
            <div className="relative group" key={index}>
              {/* <button
              aria-label="Remove Search"
              className="bg-white dark:bg-tertiary-950 z-10 absolute -top-1.5 left-4 text-[#656363] group-hover:bg-transparent transition-colors duration-150"
            >
              <TimesIcon />
            </button> */}
              <Button
                variant="default-dimmed"
                buttonStyle="outlined"
                className="!px-6"
                href={`/search?q=${term}`}
              >
                {term}
              </Button>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm text-tertiary text-start">
          You haven't searched for anything yet. Start searching to see your
          recent searches here.
        </p>
      )}
    </div>
  );
};

export default RecentSearches;
