import { useState } from "react";
import { cn } from "@/utils/utils";
import { AnimatePresence, motion } from "framer-motion";
import FilteredPicker from "./FilteredPicker";

import ChevronIcon from "@/assets/icons/chevron_down.svg?react";

const MobileCurrencyPicker = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button
        className="text-sm font-medium flex items-center justify-between w-full ps-[52px] pe-[62px]"
        onClick={() => setIsOpen(!isOpen)}
      >
        Currency
        <ChevronIcon
          className={cn("text-tertiary transition-transform duration-200", {
            "-rotate-180": isOpen,
          })}
        />
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-tertiary-50 dark:bg-[#232323] mt-[18px] pt-[18px] px-2 space-y-[26px] origin-top"
          >
            <FilteredPicker
              wrapperClassName="max-h-[140px]"
              searchInputClassName="bg-white dark:!bg-[#333333] !py-2"
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileCurrencyPicker;
