import { cn } from "@/utils/utils";
import ReactSlider from "react-slider";

interface LinePlayerProps {
  className?: string;
  desktopOnly?: boolean;
  currentTime?: number;
  duration?: number;
  handleChangeTime: (percentage: number) => void;
}

const LinePlayer = ({
  className,
  desktopOnly,
  currentTime,
  duration,
  handleChangeTime,
}: LinePlayerProps) => {
  return (
    <div
      className={cn(
        "relative flex-1 min-w-[auto]",
        {
          "max-lg:hidden": desktopOnly,
        },
        className
      )}
    >
      <ReactSlider
        value={duration ? ((currentTime ?? 0) / duration) * 100 : 0}
        step={0.01}
        onChange={(percentage) => handleChangeTime(percentage)}
        className="!absolute size-full flex items-center top-1/2 -translate-y-1/2 inset-0"
        renderTrack={(props, state) => (
          <div
            key={props.key}
            style={props.style}
            className="h-[2px] rounded-full"
          >
            <div
              className={cn("h-full rounded-full", {
                "bg-[#E3E3E3] dark:bg-[#5C5B5B]": state.index !== 0,
                "bg-accent-blue dark:bg-accent-main": state.index === 0,
              })}
            />
          </div>
        )}
        thumbClassName="rounded-full bg-accent-blue dark:bg-accent-main outline-none size-[10px]"
      />
    </div>
  );
};

export default LinePlayer;
