import {
  isLoggedInSelector,
  logout,
  setCurrency,
  updateUserInfo,
  userSelector,
} from "@/redux/App";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import { resetCart } from "@/redux/Cart";
import { useRequest } from "./useRequest";
import { Currency } from "@/shared/types/currencies";
import { useQuery } from "@tanstack/react-query";

const useFetchUserInfo = () => {
  const isLoggedIn = useAppSelector((state) => isLoggedInSelector(state));
  const user = useAppSelector((state) => userSelector(state));
  const dispatch = useAppDispatch();
  const { sendRequest } = useRequest();

  const fetchUserInfo = async () => {
    try {
      const res = await sendRequest({
        method: "GET",
        url: "Accounts/UserInfo",
      });
      if (res.data?.id) {
        dispatch(
          updateUserInfo({
            id: res.data?.id,
            email: res.data?.email,
            fullName: res.data?.fullName,
            bankSetup: res.data?.bankSetup ?? false,
            categorySetup: res.data?.categorySetup ?? false,
            profilePicture: res.data?.profilePicture ?? null,
            roles: res.data?.role?.$values ?? [],
            kycStatus: res.data?.kycStatus ?? null,
            companyId: res.data?.companyId ?? null,
            companySetup: res.data?.companySetup ?? false,
          })
        );
        // Set currency
        dispatch(setCurrency(res.data?.preferredCurrency as Currency));
        return res.data;
      }
    } catch (error) {
      dispatch(logout());
      dispatch(resetCart());
    }
  };

  const { isLoading: loading } = useQuery({
    queryKey: ["user"],
    queryFn: fetchUserInfo,
    enabled: isLoggedIn && !user.id,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  return { loading };
};

export default useFetchUserInfo;
