import { forwardRef, useEffect, useRef } from "react";
import { animate } from "framer-motion";
import {
  cartAnimateSelector,
  cartProductsCountSelector,
  resetAnimation,
} from "@/redux/Cart";
import { useAppDispatch, useAppSelector } from "@/redux/hooks";
import clsx from "clsx";
import Button from "@/components/Button";

import CartIcon from "@/assets/icons/cart.svg?react";

const CartButton = forwardRef((props, ref: any) => {
  const dispatch = useAppDispatch();
  const cartProductsCount = useAppSelector(cartProductsCountSelector);
  const animateCart = useAppSelector(cartAnimateSelector);
  const nbRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (animateCart && nbRef.current) {
      // Animate number
      animate([
        [
          nbRef.current,
          {
            scale: 2,
          },
          { duration: 0.25 },
        ],
        [
          nbRef.current,
          {
            scale: 1,
          },
          { duration: 0.15 },
        ],
      ]);
      dispatch(resetAnimation());
    }
  }, [animateCart, nbRef, dispatch]);

  return (
    <Button
      href={cartProductsCount === 0 ? "/cart" : undefined}
      className={clsx("!overflow-visible", {
        "!bg-accent-blue dark:!bg-accent-main": cartProductsCount > 0,
      })}
      variant="default-dimmed"
      tiny={true}
      icon={CartIcon}
      iconClassName={clsx({
        "dark:!text-black": cartProductsCount > 0,
      })}
      ref={ref}
      {...props}
    >
      {cartProductsCount > 0 && (
        <span
          className="absolute -top-2 -end-2 px-2 h-6 rounded-full bg-tertiary-925/45 dark:bg-white/45 text-[11px] font-semibold flex items-center justify-center backdrop-blur-sm !text-white"
          ref={nbRef}
        >
          {cartProductsCount}
        </span>
      )}
    </Button>
  );
});

export default CartButton;
