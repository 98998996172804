import { useEffect, useState } from "react";
import Button from "./Button";
import { AnimatePresence, motion } from "framer-motion";

import LoadingImage from "@/assets/images/mohtawa_loading.gif";

const PageLoader = () => {
  const [refresh, showRefresh] = useState(false);

  const handleRefreshClick = () => {
    window.location.reload();
  };

  useEffect(() => {
    setTimeout(() => {
      showRefresh(true);
    }, 3000);
  }, []);

  return (
    <div className="inset-0 w-full h-full fixed bg-white dark:bg-black z-50 flex items-center justify-center overflow-y-auto">
      <div className="space-y-8 text-center">
        <div className="relative py-10">
          <div
            style={
              {
                "--size": 200,
                "--duration": 3,
                "--anchor": 35,
                "--border-width": 2,
                "--color-from": "#418FE8",
                "--color-to": "#8A69CC",
                "--dark-color-from": "#627B00",
                "--dark-color-to": "#C3F500",
                "--delay": `-${0}s`,
              } as React.CSSProperties
            }
            className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 pointer-events-none [border:calc(var(--border-width)*1px)_solid_transparent] ![mask-clip:padding-box,border-box] ![mask-composite:intersect] [mask:linear-gradient(transparent,transparent),linear-gradient(white,white)] after:absolute after:aspect-square after:w-[calc(var(--size)*1px)] after:animate-border-beam after:[animation-delay:var(--delay)] after:[background:linear-gradient(to_left,var(--color-from),var(--color-to),transparent)] dark:after:[background:linear-gradient(to_left,var(--dark-color-from),var(--dark-color-to),transparent)] after:[offset-anchor:calc(var(--anchor)*1%)_50%] after:[offset-path:rect(0_auto_auto_0_round_calc(var(--size)*1px))] rounded-full size-36"
          ></div>
          <img
            src={LoadingImage}
            alt="Mohtawa"
            className="mx-auto dark:invert size-[81px]"
          />
        </div>
        <div className="min-h-8">
          <AnimatePresence>
            {refresh && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="text-xs text-tertiary px-2"
              >
                Taking too long to load?{" "}
                <Button variant="link" onClick={() => handleRefreshClick()}>
                  Try refreshing the page
                </Button>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );
};

export default PageLoader;
