// Icons
import PhotoIcon from "@/assets/icons/categories/photo.svg?react";
import PhotoOutlineIcon from "@/assets/icons/categories/photo_outline.svg?react";
import VideoIcon from "@/assets/icons/categories/video.svg?react";
import VectorIcon from "@/assets/icons/categories/vector.svg?react";
import SoundIcon from "@/assets/icons/categories/sound.svg?react";
import ARIcon from "@/assets/icons/categories/ar.svg?react";
import GraphicIcon from "@/assets/icons/categories/graphic.svg?react";
import ThreeDIcon from "@/assets/icons/categories/3d.svg?react";
import SpatialIcon from "@/assets/icons/categories/spatial.svg?react";
import AllIcon from "@/assets/icons/misc/colors.svg?react";

export const categories = [
  {
    id: "photo",
    name: "Photo",
    icon: PhotoIcon,
  },
  {
    id: "video",
    name: "Video",
    icon: VideoIcon,
  },
];

export const creatorCategories = [
  {
    id: "photo",
    name: "Photo",
    icon: PhotoOutlineIcon,
  },
  {
    id: "video",
    name: "Video",
    icon: VideoIcon,
  },
  {
    id: "graphic",
    name: "Graphic",
    icon: GraphicIcon,
  },
  {
    id: "3d",
    name: "3D art",
    icon: ThreeDIcon,
  },
  {
    id: "audio",
    name: "Audio",
    icon: SoundIcon,
  },
  {
    id: "ar",
    name: "AR",
    icon: ARIcon,
  },
  {
    id: "illustration",
    name: "Illustration",
    icon: VectorIcon,
  },
  {
    id: "spatial",
    name: "Spatial",
    icon: SpatialIcon,
  },
];

export const categoriesIcons = {
  "18f01772-b3ad-4c82-8c21-62f1e8fbe0ba": ThreeDIcon,
  "ee03bd87-4bfc-44ba-9ad4-700e3b39eead": SoundIcon,
  "1d895c74-855e-4b97-9dbe-67035a3343d9": ARIcon,
  "81ff5dd7-ce4c-4617-90f7-c6ad16c77aeb": GraphicIcon,
  "3fd63b8f-7446-4ecd-b303-2d4be837f2a8": VectorIcon,
  "8727d2f4-2492-47b1-8440-879d0c0d2e28": PhotoIcon,
  "ec48e8b0-ad30-415a-8214-cda06a122e58": SpatialIcon,
  "da146edd-1b88-4e88-bf5e-0bc2f61f57e1": VectorIcon,
  "d26bafd9-7ae8-43b0-ad0f-302b89126fe9": VideoIcon,
  all: AllIcon,
};
