interface CarouselPillProps {
  children: React.ReactNode;
}

const CarouselPill = ({ children }: CarouselPillProps) => {
  return (
    <div className="w-[216px] h-[68px] flex items-center justify-center rounded-full border-[2px] border-white font-medium text-[25px]">
      {children}
    </div>
  );
};

export default CarouselPill;
